import React from 'react';
import ReactDOM from 'react-dom';

const BackDrop = (props) => {
  return <div className='backdrop' onClick={props.onClose} />;
};

const ModalOverlay = (props) => {
  return (
    <div className='modal'>
      <div>{props.children}</div>
    </div>
  );
};

const portalElement = document.getElementById('overlays');

const Modal = (props) => {
  return (
    <>
      {ReactDOM.createPortal(<BackDrop onClose={props.onClose} />, portalElement)}
      {ReactDOM.createPortal(<ModalOverlay className={props.className}>{props.children}</ModalOverlay>, portalElement)}
    </>
  );
};

export default Modal;
