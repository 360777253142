import React, { useState, useContext } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import ConfirmProductComplete from './modals/ConfirmProductComplete';
import ConfirmProductCancel from './modals/ConfirmProductCancel';
import Button from '../UI/Button';
import { AuthContext } from '../context/auth-context';
import { dateFormatter } from '../utils/dateFormatter';
import { timeFormatter } from '../utils/timeFormatter';
import { convertToKilos } from '../utils/convertToKilos';

const AdminProductRequests = (props) => {
  const auth = useContext(AuthContext);

  const [completedOrder, setCompletedOrder] = useState({});
  const [cancelledOrder, setCancelledOrder] = useState({});
  const [confirmProductComplete, setConfirmProductComplete] = useState(false);
  const [confirmCancelOrder, setConfirmCancelOrder] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // filters unique extras from order
  let uniqueExtras = (array) => {
    return [...new Map(array.map((item) => [item['name'], item])).values()];
  };

  // counts how many of each extra there is
  const extraCounter = (array, item) => {
    let counter = 0;
    for (let i = 0; i < array.length; i++) {
      if (array[i].name === item) counter++;
    }
    return counter;
  };

  // updates repsonded checkbox
  const updateResponded = async (request) => {
    if (request.responded === false) {
      try {
        await axios
          .put(
            `${process.env.REACT_APP_BACKEND_URL}/api/product-enquiry/responded/${request._id}`,
            { responded: true },
            { headers: { Authorization: 'Bearer ' + auth.userData.token } }
          )
          .then((res) => {
            props.onUpdate(res.data);
          });
      } catch (err) {
        setError(true);
        setErrorMessage(err.response.data.message);
        setTimeout(() => setError(false), 2000);
      }
    } else {
      try {
        await axios
          .put(
            `${process.env.REACT_APP_BACKEND_URL}/api/product-enquiry/responded/${request._id}`,
            { responded: false },
            { headers: { Authorization: 'Bearer ' + auth.userData.token } }
          )
          .then((res) => {
            props.onUpdate(res.data);
          });
      } catch (err) {
        setError(true);
        setErrorMessage(err.response.data.message);
        setTimeout(() => setError(false), 2000);
      }
    }
  };

  // displays confirm modal and passes data to it
  const completeOrderHandler = (request) => {
    setConfirmProductComplete(true);
    setCompletedOrder({
      request,
    });
  };

  // displays cancel modal and passes data to it
  const cancelOrderHandler = (request) => {
    setConfirmCancelOrder(true);
    setCancelledOrder({
      request,
    });
  };

  return (
    <>
      {props.productRequests.length === 0 && <p>You have no order requests!</p>}
      {props.productRequests.length > 1 && (
        <h3>
          You have <big>Product</big> requests!
        </h3>
      )}
      {props.productRequests.length === 1 && (
        <h3>
          You have a <big>Product</big> request!
        </h3>
      )}
      {props.productRequests.length > 0 && (
        <div className='table-container'>
          <table>
            <thead>
              <tr>
                <th>Submitted</th>
                <th>Name, Email &amp; Address</th>
                <th>Bag</th>
                <th>Sweets</th>
                <th>Other Products</th>
                <th>Additional Info</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {props.productRequests.map((request) => (
                <tr key={request._id}>
                  <td>
                    {dateFormatter(request.submittedOn)}
                    <br />
                    {timeFormatter(request.submittedOn)}
                    <br />
                    <button className='delete-button' onClick={() => cancelOrderHandler(request)}>
                      Cancel
                    </button>
                  </td>
                  <td>
                    <b>{request.name}</b>
                    <br />
                    {request.email}
                    <br />
                    <br />
                    {request.address}
                    <br />
                    {request.postcode}
                  </td>
                  <td>
                    {request.order
                      .map((b) =>
                        b.baggs.length > 0
                          ? b.baggs
                              .map((o) => (
                                <div key={o.id}>
                                  {convertToKilos(o.size)}
                                  <br />
                                  {o.bagg} Bag
                                  <br />
                                  <b>Qty: {o.quantity || '1'}</b>
                                </div>
                              ))
                              .reduce((prev, curr) => [prev, <hr key={uuidv4()} />, curr])
                          : 'N/A'
                      )
                      .reduce((prev, curr) => [prev, <hr key={uuidv4()} />, curr])}
                  </td>
                  <td>
                    {request.order
                      .map((b) =>
                        b.baggs.length > 0
                          ? b.baggs
                              .map((o) =>
                                o.sweets.length > 0 ? (
                                  <React.Fragment key={o.id}>
                                    {o.sweets.map((s) => <React.Fragment key={s.sweet}>{s.sweet}</React.Fragment>).reduce((prev, curr) => [prev, ', ', curr])}
                                    <br />
                                    <b>{` (${o.sweets.length} x ${convertToKilos((o.size / o.sweets.length).toFixed(0))})`}</b>
                                  </React.Fragment>
                                ) : (
                                  'N/A'
                                )
                              )
                              .reduce((prev, curr) => [prev, <hr key={uuidv4()} />, curr])
                          : 'N/A'
                      )
                      .reduce((prev, curr) => [prev, <hr key={uuidv4()} />, curr])}
                  </td>
                  <td>
                    {request.order.map((o) =>
                      o.extras.length > 0
                        ? uniqueExtras(o.extras)
                            .map((e) => `${extraCounter(o.extras, e.name)} x ${e.name}`)
                            .reduce((prev, curr) => [prev, <hr key={uuidv4()} />, curr])
                        : 'N/A'
                    )}
                  </td>
                  <td>{request.message ? request.message : 'N/A'}</td>
                  <td>
                    <h3 className='product-info' style={{ padding: '5px' }}>
                      £{parseFloat(request.totalPrice).toFixed(2)}
                    </h3>
                    <input
                      type='checkbox'
                      className='checkbox'
                      checked={request.responded === true ? 'checked' : ''}
                      onChange={() => updateResponded(request)}
                    />
                    <br />
                    Responded
                    {request.responded === true && (
                      <>
                        <p>
                          {dateFormatter(request.respondedDate)}
                          <br />
                          {timeFormatter(request.respondedDate)}
                        </p>
                        <hr />
                        <Button onClick={() => completeOrderHandler(request)}>Complete</Button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {error && (
        <div className='error-modal-text'>
          <h3>{errorMessage}</h3>
        </div>
      )}
      {confirmProductComplete && (
        <ConfirmProductComplete onHide={() => setConfirmProductComplete(false)} request={completedOrder.request} onUpdate={props.onUpdate} />
      )}
      {confirmCancelOrder && <ConfirmProductCancel onHide={() => setConfirmCancelOrder(false)} request={cancelledOrder.request} onUpdate={props.onUpdate} />}
    </>
  );
};

export default AdminProductRequests;
