import React, { useState, useContext } from 'react';
import axios from 'axios';

import Modal from '../../UI/Modal';
import Button from '../../UI/Button';
import Spinner from '../../UI/Spinner';
import { AuthContext } from '../../context/auth-context';

const ConfirmDelete = (props) => {
  const auth = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // deletes sweet
  const deleteProductHandler = () => {
    const deleteProduct = async () => {
      setIsLoading(true);
      try {
        await axios
          .delete(`${process.env.REACT_APP_BACKEND_URL}/api/${props.productType}/${props.id}`, {
            headers: { Authorization: 'Bearer ' + auth.userData.token },
            data: { image: props.image, preview: props.preview },
          })
          .then((res) => {
            setDeleted(true);
            setIsLoading(false);
            setTimeout(() => {
              props.onRefreshProducts(res.data);
              props.onHideConfirmDelete();
            }, 2000);
          });
      } catch (err) {
        setError(true);
        setErrorMessage(err.response.data.message);
        setIsLoading(false);
        setTimeout(() => setError(false), 2000);
      }
    };
    deleteProduct();
  };

  return (
    <Modal onClose={props.onHideConfirmDelete}>
        <div className='modal-title'>
        <h2>Are you sure you want to delete?</h2>
      </div>
      <div className='modal-column'>
        <h2>{props.name}</h2>
        <img src={props.image} alt={props.name} className='confirm-delete__image' />
      </div>
      {deleted && (
        <div className='modal-text'>
          <h3>Deleted successfully!</h3>
        </div>
      )}
      {error && (
        <div className='error-modal-text'>
          <h3>{errorMessage}</h3>
        </div>
      )}
      <div className='modal-actions'>
        {!deleted && !isLoading && (
          <Button className='remove-button' onClick={props.onHideConfirmDelete}>
            Cancel
          </Button>
        )}
        {isLoading ? <Spinner /> : !deleted && <Button onClick={deleteProductHandler}>Delete</Button>}
      </div>
    </Modal>
  );
};

export default ConfirmDelete;
