// formats time
export const timeFormatter = (time) => {
  let hours;
  let minutes;
  if (new Date(time).getHours() < 10) {
    hours = '0' + new Date(time).getHours();
  } else {
    hours = new Date(time).getHours();
  }
  if (new Date(time).getMinutes() < 10) {
    minutes = '0' + new Date(time).getMinutes();
  } else {
    minutes = new Date(time).getMinutes();
  }
  return (time = `${hours}:${minutes}`);
};
