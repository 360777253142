import React from 'react';

const SweetTypeCheckbox = (props) => {
  const checkHandler = () => {
    props.onCheckType(props.filter);
  };

  return (
    <div className='checkbox-check'>
      <input type='checkbox' className={props.checkbox} onChange={checkHandler} />
    </div>
  );
};

export default SweetTypeCheckbox;
