import React, { useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import Button from '../UI/Button';
import Spinner from '../UI/Spinner';
import { AuthContext } from '../context/auth-context';

const AdminLogin = () => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const logInHandler = async (event) => {
    event.preventDefault();
    const email = emailInputRef.current.value.trim();
    const password = passwordInputRef.current.value.trim();
    setLoading(true);
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/users/login`, { email, password });
      setLoading(false);
      auth.login(data);
      navigate('/admin');
    } catch (err) {
      setLoading(false);
      setError(true);
      setErrorMessage(err.response.data.message);
      setTimeout(() => setError(false), 2000);
    }
  };

  return (
    <>
      <h1>Log In</h1>
      <form className='login' onSubmit={logInHandler}>
        <input id='email' type='email' placeholder='Email Address' ref={emailInputRef} />
        <input id='password' type='password' placeholder='Password' ref={passwordInputRef} />
        <div>{loading ? <Spinner /> : <Button type='submit'>Login</Button>}</div>
      </form>
      {error && (
        <div className='error-modal-text'>
          <h3>{errorMessage}</h3>
        </div>
      )}
    </>
  );
};

export default AdminLogin;
