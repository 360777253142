import React, { useState, useRef, useContext } from 'react';
import axios from 'axios';

import Modal from '../../UI/Modal';
import Button from '../../UI/Button';
import Spinner from '../../UI/Spinner';
import { AuthContext } from '../../context/auth-context';
import { capitaliseWords } from '../../utils/capitaliseWords';

const AddCategory = (props) => {
  const auth = useContext(AuthContext);

  const categoryInputRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [added, setAdded] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // posts new filter
  const postFilter = async (filter) => {
    setIsLoading(true);
    try {
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/filters`,
          { filter },
          { headers: { Authorization: 'Bearer ' + auth.userData.token } }
        )
        .then((res) => {
          props.onRefreshFilters(res.data);
          setIsLoading(false);
          setAdded(true);
          setTimeout(() => props.onClose(), 2000);
        });
    } catch (err) {
      setError(true);
      setErrorMessage(err.response.data.message);
      setIsLoading(false);
      setTimeout(() => setError(false), 2000);
    }
  };

  // checks if form is empty and if category already exists, then adds category if okay
  const addNewCategoryHandler = () => {
    if (!categoryInputRef.current.value) {
      setError(true);
      setErrorMessage('Please enter a valid category!');
      return;
    }
    const newCategory = capitaliseWords(categoryInputRef.current.value);
    if (props.filters.some((filter) => filter.filter === newCategory)) {
      setError(true);
      setErrorMessage('Category already exists!');
      return;
    }
    postFilter(newCategory);
  };

  const resetHandler = () => {
    setError(false);
  };

  return (
    <Modal onClose={props.onClose}>
      <div className='modal-title'>
        <h2>Add category</h2>
      </div>
      <div className='modal-text'>
        <input id='category' type='text' ref={categoryInputRef} onFocus={resetHandler} placeholder='New Category' />
      </div>
      {added && (
        <div className='modal-text'>
          <h3>'{categoryInputRef.current.value}' added successfully!</h3>
        </div>
      )}
      {error && (
        <div className='error-modal-text'>
          <h3>{errorMessage}</h3>
        </div>
      )}
      <div className='modal-actions'>
        {!added && !isLoading && (
          <Button className='remove-button' onClick={props.onClose}>
            Close
          </Button>
        )}
        {isLoading ? <Spinner /> : !added && <Button onClick={addNewCategoryHandler}>Add Category</Button>}
      </div>
    </Modal>
  );
};

export default AddCategory;
