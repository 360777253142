import { v4 as uuidv4 } from 'uuid';

export const orderReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_BAGG':
      const baggId = uuidv4();
      return {
        ...state,
        baggSelection: { ...state.baggSelection, id: baggId, bagg: action.payload.bagg, image: action.payload.image, quantity: 1 },
        screen: { ...state.screen, visibleExtras: false, visibleBaggs: false, visibleSizes: true },
      };
    case 'ADD_SIZE':
      if (state.baggSelection.bagg === 'Build Your Own') {
        return {
          ...state,
          baggSelection: { ...state.baggSelection, size: action.payload.size, price: action.payload.price, amount: action.payload.amount },
          screen: { ...state.screen, visibleSizes: false, visibleAddBasket: true, visibleSweets: true },
        };
      }
      if (state.baggSelection.bagg !== 'Build Your Own') {
        return {
          ...state,
          baggSelection: { ...state.baggSelection, size: action.payload.size, price: action.payload.price, amount: action.payload.amount },
          screen: { ...state.screen, visibleSizes: false, visibleAddBasket: true },
        };
      }
      break;
    case 'CHANGE_SIZE':
      return {
        ...state,
        baggSelection: { ...state.baggSelection, size: null, price: null, amount: null },
        screen: { ...state.screen, visibleSizes: true, visibleAddBasket: false, visibleSweets: false },
      };
    case 'ADD_SWEET':
      return {
        ...state,
        baggSelection: {
          ...state.baggSelection,
          sweets: [...state.baggSelection.sweets, { sweet: action.payload.sweet, image: action.payload.image }],
        },
      };
    case 'REMOVE_SWEET':
      const sweetRemoved = state.baggSelection.sweets.filter((s) => s.sweet !== action.payload);
      return {
        ...state,
        baggSelection: { ...state.baggSelection, sweets: sweetRemoved },
      };
    case 'ADD_TO_BASKET':
      const baggsInBasket = [...state.basket.baggs];
      let checkBasketForBagg;
      if (state.baggSelection.bagg === 'Build Your Own') {
        checkBasketForBagg = state.basket.baggs.filter(
          (bagg) => bagg.bagg === state.baggSelection.bagg && bagg.size === state.baggSelection.size && bagg.sweets === state.baggSelection.sweets
        );
        // create 2 loops to get all sweets from the selection and the basket, sort them and compare
      } else {
        checkBasketForBagg = state.basket.baggs.filter((bagg) => bagg.bagg === state.baggSelection.bagg && bagg.size === state.baggSelection.size);
      }
      if (checkBasketForBagg.length > 0) {
        const basketIndex = state.basket.baggs.findIndex((b) => b.id === checkBasketForBagg[0].id);
        const newQuantity = checkBasketForBagg[0].quantity + action.payload.quantity;
        baggsInBasket[basketIndex].quantity = newQuantity;
        return {
          basket: { ...state.basket, baggs: baggsInBasket },
          baggSelection: { sweets: [] },
          screen: { visibleBaggs: true, visibleExtras: true },
        };
      } else {
        return {
          basket: { ...state.basket, baggs: [...state.basket.baggs, state.baggSelection] },
          baggSelection: { sweets: [] },
          screen: { visibleBaggs: true, visibleExtras: true },
        };
      }
    case 'REMOVE_BAGG_FROM_BASKET':
      const removedFromBasket = state.basket.baggs.filter((b) => b.id !== action.payload);
      return { ...state, basket: { ...state.basket, baggs: removedFromBasket } };
    case 'INCREASE_SELECTED_BAGG_QTY':
      const increasedQuantity = state.baggSelection.quantity + 1;
      return {
        ...state,
        baggSelection: { ...state.baggSelection, quantity: increasedQuantity },
      };
    case 'DECREASE_SELECTED_BAGG_QTY':
      let decreasedQuantity;
      if (state.baggSelection.quantity > 1) {
        decreasedQuantity = state.baggSelection.quantity - 1;
      } else {
        decreasedQuantity = 1;
      }
      return {
        ...state,
        baggSelection: { ...state.baggSelection, quantity: decreasedQuantity },
      };
    case 'INCREASE_BASKET_BAGG_QTY':
      const increaseIndex = state.basket.baggs.findIndex((b) => b.id === action.payload.id);
      const increasedQuantityBasket = [...state.basket.baggs];
      increasedQuantityBasket[increaseIndex].quantity = action.payload.quantity + 1;
      return {
        ...state,
        basket: { ...state.basket, baggs: increasedQuantityBasket },
      };
    case 'DECREASE_BASKET_BAGG_QTY':
      const decreaseIndex = state.basket.baggs.findIndex((b) => b.id === action.payload.id);
      const decreasedQuantityBasket = [...state.basket.baggs];
      if (decreasedQuantityBasket[decreaseIndex].quantity > 1) {
        decreasedQuantityBasket[decreaseIndex].quantity = action.payload.quantity - 1;
      }
      return {
        ...state,
        basket: { ...state.basket, baggs: decreasedQuantityBasket },
      };
    case 'ADD_EXTRA':
      let newExtras = [];
      for (let i = 0; i < action.payload.quantity; i++) {
        newExtras.push({
          id: uuidv4(),
          stock: action.payload.stock,
          name: action.payload.name,
          image: action.payload.image,
          price: action.payload.price,
        });
      }
      return {
        ...state,
        basket: {
          ...state.basket,
          extras: [...state.basket.extras, ...newExtras],
        },
      };
    case 'REMOVE_SINGLE_EXTRA_FROM_BASKET':
      const removedSingleExtraFromBasket = state.basket.extras.filter((b) => b.id !== action.payload);
      return { ...state, basket: { ...state.basket, extras: removedSingleExtraFromBasket } };
    case 'REMOVE_EXTRA_FROM_BASKET':
      const removedExtraFromBasket = state.basket.extras.filter((b) => b.name !== action.payload);
      return { ...state, basket: { ...state.basket, extras: removedExtraFromBasket } };
    case 'RESET_BAGG':
      return {
        ...state,
        baggSelection: { sweets: [] },
        screen: { visibleBaggs: true, visibleExtras: true },
      };
    case 'START_AGAIN':
      return {
        basket: { baggs: [], extras: [] },
        baggSelection: { sweets: [] },
        screen: { visibleBaggs: true, visibleExtras: true },
      };
    default:
      return state;
  }
};
