import React from 'react';
import { HiOutlineMail } from 'react-icons/hi';
import { AiFillFacebook } from 'react-icons/ai';
import { AiOutlineInstagram } from 'react-icons/ai';

const ContactDetails = () => {
  return (
    <>
      <div className='contact'>
        <a href='mailto:baggsofsweets@aol.com' target='blank'>
          <HiOutlineMail className='socialIcon' />
        </a>
        <a href='http://www.facebook.com/baggsofsweets' target='blank'>
          <AiFillFacebook className='socialIcon' />
        </a>
        <a href='http://www.instagram.com/baggsofsweets' target='blank'>
          <AiOutlineInstagram className='socialIcon' />
        </a>
      </div>
    </>
  );
};

export default ContactDetails;
