import React, { useState, useRef, useContext } from 'react';
import axios from 'axios';

import Button from '../UI/Button';
import Spinner from '../UI/Spinner';
import ImageUpload from '../UI/ImageUpload';
import { capitaliseWords } from '../utils/capitaliseWords';
import { AuthContext } from '../context/auth-context';
import { removeFromArray } from '../utils/removeFromArray';

const AddProduct = (props) => {
  const auth = useContext(AuthContext);

  const nameInputRef = useRef();
  const categoryInputRef = useRef();
  const [sweetType, setSweetType] = useState([]);
  const [added, setAdded] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [image, setImage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // selects image
  const selectImageHandler = (selectedImage) => {
    setImage(selectedImage);
  };

  // updates gluten free
  const glutenFreeHandler = () => {
    if (!sweetType.includes('glutenFree')) {
      setSweetType((sweetType) => {
        return [...sweetType, 'glutenFree'];
      });
    }
    if (sweetType.includes('glutenFree')) {
      let removedFilter = [...new Set(removeFromArray(sweetType, 'glutenFree'))];
      setSweetType(() => removedFilter);
    }
  };

  // updates vegetarian
  const vegetarianHandler = () => {
    if (!sweetType.includes('vegetarian')) {
      setSweetType((sweetType) => {
        return [...sweetType, 'vegetarian'];
      });
    }
    if (sweetType.includes('vegetarian')) {
      let removedFilter = [...new Set(removeFromArray(sweetType, 'vegetarian'))];
      setSweetType(() => removedFilter);
    }
  };

  // updates vegan
  const veganHandler = () => {
    if (!sweetType.includes('vegan')) {
      setSweetType((sweetType) => {
        return [...sweetType, 'vegan'];
      });
    }
    if (sweetType.includes('vegan')) {
      let removedFilter = [...new Set(removeFromArray(sweetType, 'vegan'))];
      setSweetType(() => removedFilter);
    }
  };

  // posts new sweet
  const postSweet = async (name, category, image, types) => {
    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('category', category);
      formData.append('image', image);
      formData.append('types', types);
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/api/products`, formData, {
          headers: { Authorization: 'Bearer ' + auth.userData.token },
        })
        .then((res) => {
          props.onRefreshProducts(res.data);
          setIsLoading(false);
          setAdded(true);
          setTimeout(() => {
            nameInputRef.current.value = '';
            categoryInputRef.current.value = '';
            setSweetType([]);
            setAdded(false);
            setImage(null);
          }, 2000);
        });
    } catch (err) {
      setIsLoading(false);
      setError(true);
      setErrorMessage(err.response.data.message);
      setTimeout(() => setError(false), 2000);
    }
  };

  // validates and adds new sweet
  const addProductHandler = () => {
    if (!nameInputRef.current.value) {
      setError(true);
      setErrorMessage('Please enter a name for your new sweet!');
      setTimeout(() => setError(false), 2000);
      return;
    }
    if (!categoryInputRef.current.value) {
      setError(true);
      setErrorMessage('Please select a category for your new sweet!');
      setTimeout(() => setError(false), 2000);
      return;
    }
    if (!image) {
      setError(true);
      setErrorMessage('Please upload an image for your new sweet!');
      setTimeout(() => setError(false), 2000);
      return;
    }
    const name = capitaliseWords(nameInputRef.current.value);
    const category = categoryInputRef.current.value;
    if (props.products.some((e) => e.name === name)) {
      setError(true);
      setErrorMessage('Sweet already exists!');
      setTimeout(() => setError(false), 2000);
      return;
    }
    postSweet(name, category, image, sweetType);
  };

  // resets errors
  const resetHandler = () => {
    setError(false);
  };

  return (
    <>
      <h2>Add Sweet</h2>
      <div className='modal-text'>
        <input type='text' ref={nameInputRef} size='50' onFocus={resetHandler} placeholder='Sweet Name' />
      </div>
      <div className='modal-text'>
        <label htmlFor='category'>Category:</label>
        <select name='category' ref={categoryInputRef} onFocus={resetHandler} style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
          <option value='' style={{ fontSize: '0.9rem' }} hidden></option>
          {props.filters.sort().map((filter) => (
            <option key={filter.filter} value={filter.filter} style={{ fontSize: '0.9rem', fontWeight: 'bold', padding: '0px 5px' }}>
              {filter.filter}
            </option>
          ))}
        </select>
      </div>
      <ImageUpload id='image' onSelectImage={selectImageHandler} onDelete={image} />
      <div className='modal-text'>
        <div className='img-gluten-text' style={{ position: 'static' }}>
          GF
        </div>
        <div>
          <input type='checkbox' className='checkbox' checked={sweetType.includes('glutenFree')} onChange={glutenFreeHandler} />
        </div>
        <div className='img-veggie-text' style={{ position: 'static', marginLeft: '10px' }}>
          V
        </div>
        <div>
          <input type='checkbox' className='checkbox' checked={sweetType.includes('vegetarian')} onChange={vegetarianHandler} />
        </div>
        <div className='img-vegan-text' style={{ position: 'static', marginLeft: '10px' }}>
          Vg
        </div>
        <div>
          <input type='checkbox' className='checkbox' checked={sweetType.includes('vegan')} onChange={veganHandler} />
        </div>
      </div>
      <div className='modal-actions'>{isLoading ? <Spinner /> : <Button onClick={addProductHandler}>Add Product</Button>}</div>
      {added && (
        <div className='modal-text'>
          <h3>'{capitaliseWords(nameInputRef.current.value)}' added successfully!</h3>
        </div>
      )}
      {error && (
        <div className='error-modal-text'>
          <h3>{errorMessage}</h3>
        </div>
      )}
    </>
  );
};

export default AddProduct;
