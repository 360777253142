import React, { useState, useContext } from 'react';
import axios from 'axios';

import ConfirmDelete from './modals/ConfirmDelete';
import Button from '../UI/Button';
import { capitalise } from '../utils/capitaliseWord';
import { capitaliseWords } from '../utils/capitaliseWords';
import { AuthContext } from '../context/auth-context';

import '../DeleteProduct.css';

const DeleteProduct = ({ id, name, image, preview, category, recentlyAdded, inStock, onRefreshProducts, categories }) => {
  const auth = useContext(AuthContext);

  const [nameInput, setNameInput] = useState(capitaliseWords(name));
  const [categoryInput, setCategoryInput] = useState(capitalise(category));
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [edited, setEdited] = useState(false);
  const [visibleConfirmDelete, setVisibleConfirmDelete] = useState(false);
  const [isRecentlyAdded, setIsRecentlyAdded] = useState(recentlyAdded);
  const [isInStock, setIsInStock] = useState(inStock);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // toggles edit mode
  const editModeHandler = () => {
    setEditMode((prevState) => !prevState);
  };

  const nameInputHandler = (event) => {
    setNameInput(event.target.value);
  };

  const categoryInputHandler = (event) => {
    setCategoryInput(event.target.value);
  };

  // edits bagg name
  const editProductHandler = () => {
    const editProduct = async (newName, newCategory) => {
      setIsLoading(true);
      try {
        await axios
          .put(
            `${process.env.REACT_APP_BACKEND_URL}/api/products/${id}`,
            { name: newName, category: newCategory },
            { headers: { Authorization: 'Bearer ' + auth.userData.token } }
          )
          .then((res) => {
            setEditMode(false);
            onRefreshProducts(res.data);
            setEdited(true);
            setIsLoading(false);
            setTimeout(() => setEdited(false), 2000);
          });
      } catch (err) {
        setError(true);
        setErrorMessage(err.response.data.message);
        setIsLoading(false);
        setTimeout(() => setError(false), 2000);
      }
    };

    const newName = capitaliseWords(nameInput);
    const newCategory = categoryInput;
    editProduct(newName, newCategory);
  };

  // show delete confirmation
  const showConfirmDeleteHandler = () => {
    setVisibleConfirmDelete(true);
  };

  // hide delete confirmation
  const hideConfirmDeleteHandler = () => {
    setVisibleConfirmDelete(false);
  };

  // updates recently added sweets on home page
  const recentlyAddedHandler = () => {
    const updateRecentlyAddedHandler = async () => {
      try {
        await axios
          .put(
            `${process.env.REACT_APP_BACKEND_URL}/api/products/recently-added/${id}`,
            { recentlyAdded: !isRecentlyAdded },
            { headers: { Authorization: 'Bearer ' + auth.userData.token } }
          )
          .then((res) => {
            setIsRecentlyAdded((prevState) => !prevState);
            onRefreshProducts(res.data);
          });
      } catch (err) {
        setError(true);
        setErrorMessage(err.response.data.message);
        setTimeout(() => setError(false), 2000);
      }
    };
    updateRecentlyAddedHandler();
  };

  // updates in stock/out of stock on order & sweets pages
  const inStockHandler = () => {
    const updateStockHandler = async () => {
      try {
        await axios
          .put(
            `${process.env.REACT_APP_BACKEND_URL}/api/products/stock/${id}`,
            { inStock: !isInStock },
            { headers: { Authorization: 'Bearer ' + auth.userData.token } }
          )
          .then((res) => {
            setIsInStock((prevState) => !prevState);
          });
      } catch (err) {
        setError(true);
        setErrorMessage(err.response.data.message);
        setTimeout(() => setError(false), 2000);
      }
    };
    updateStockHandler();
  };

  return (
    <>
      <li className={isInStock ? 'delete-product-container' : 'delete-product-container-out-of-stock'}>
        <div className='sweet-info-container'>
          <h4 className={isInStock ? 'in-stock' : 'not-in-stock'}>{isInStock ? 'In Stock' : 'Out Of Stock'}</h4>
          <input type='checkbox' className='checkbox' checked={isInStock} onChange={inStockHandler} />
        </div>
        <div className='sweet-image-container'>
          <img src={preview} alt={name} className='delete-product__image' />
          <div className='sweet-image-centered-text'>
            <h3>{name}</h3>
            <p>{category}</p>
          </div>
        </div>
        <div className='sweet-info-container'>
          <h4>New Sweet</h4>
          <input type='checkbox' className='checkbox' checked={isRecentlyAdded} onChange={recentlyAddedHandler} />
        </div>
        {!isLoading && editMode && (
          <>
            <input type='text' value={nameInput} size='25' onChange={nameInputHandler} style={{ width: 'auto' }} />
            <select value={categoryInput} onChange={categoryInputHandler}>
              {categories.map((c) => (
                <option key={c.filter}>{c.filter}</option>
              ))}
            </select>
          </>
        )}
        {!isLoading && !editMode && (
          <span>
            <Button onClick={editModeHandler}>Edit</Button>
            <button className='remove-button' onClick={showConfirmDeleteHandler}>
              Delete
            </button>
          </span>
        )}
        {editMode && (
          <span>
            <button className='remove-button' onClick={editModeHandler}>
              Back
            </button>
            <Button onClick={editProductHandler}>Confirm</Button>
          </span>
        )}
        {edited && (
          <div className='modal-text' style={{ margin: 0 }}>
            <h3>Updated!</h3>
          </div>
        )}
        {error && (
          <div className='error-modal-text'>
            <h3>{errorMessage}</h3>
          </div>
        )}
      </li>
      {visibleConfirmDelete && (
        <ConfirmDelete
          onHideConfirmDelete={hideConfirmDeleteHandler}
          id={id}
          name={name}
          image={image}
          preview={preview}
          productType={'products'}
          onRefreshProducts={onRefreshProducts}
        />
      )}
    </>
  );
};
export default DeleteProduct;
