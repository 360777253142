import React, { useState, useRef, useContext } from 'react';
import axios from 'axios';

import Modal from '../../UI/Modal';
import Button from '../../UI/Button';
import Spinner from '../../UI/Spinner';
import { AuthContext } from '../../context/auth-context';
import { capitalise } from '../../utils/capitaliseWord';

const ChangeName = (props) => {
  const auth = useContext(AuthContext);

  const nameInputRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const changeNameHandler = async () => {
    setIsLoading(true);
    try {
      let name = capitalise(nameInputRef.current.value);
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/edit/name/${auth.userData.userId}`,
        { name },
        { headers: { Authorization: 'Bearer ' + auth.userData.token } }
      );
      setIsLoading(false);
      setUpdated(true);
      auth.userData.name = name;
      localStorage.setItem('userData', JSON.stringify({ ...auth.userData, name: name }));
    } catch (err) {
      setIsLoading(false);
      setError(true);
      setErrorMessage(err.response.data.message);
      setTimeout(() => setError(false), 2000);
    }
  };

  return (
    <Modal onClose={props.onClose}>
      <div className='modal-title'>
        <h2>Change Name</h2>
      </div>
      <div className='modal-text'>
        <h2>{auth.userData.name}</h2>
      </div>
      {!updated && (
        <div className='modal-text'>
          <input id='name' type='text' ref={nameInputRef} placeholder='New Name' />
        </div>
      )}
      {updated && (
        <div className='modal-text'>
          <h3>Name updated successfully</h3>
        </div>
      )}
      {error && (
        <div className='error-modal-text'>
          <h3>{errorMessage}</h3>
        </div>
      )}
      <div className='modal-actions'>
        <Button className='remove-button' onClick={props.onClose}>
          Close
        </Button>
        {isLoading ? <Spinner /> : !updated && <Button onClick={changeNameHandler}>Change Name</Button>}
      </div>
    </Modal>
  );
};

export default ChangeName;
