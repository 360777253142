import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { FaMinusCircle } from 'react-icons/fa';
import { FaPlusCircle } from 'react-icons/fa';

import SelectedSweets from '../components/SelectedSweets';
import Sizes from '../components/Sizes';
import Baggs from '../components/Baggs';
import Extras from '../components/Extras';
import Sweets from '../components/Sweets';
import SelectedBagg from '../components/SelectedBagg';
import SweetLimit from '../components/modals/SweetLimit';
import EmptySweets from '../components/modals/EmptySweets';
import Spinner from '../UI/Spinner';
import { BasketContext } from '../context/basket-context';

const Order = () => {
  const { orderState, dispatchOrder } = useContext(BasketContext);
  const [visibleSweetLimit, setVisibleSweetLimit] = useState(false);
  const [sweetLimitSize, setSweetLimitSize] = useState();
  const [visibleEmptySweets, setVisibleEmptySweets] = useState(false);
  const [sweets, setSweets] = useState([]);
  const [extras, setExtras] = useState([]);
  const [baggs, setBaggs] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [added, setAdded] = useState(false);
  const [newBagg, setNewBagg] = useState(false);

  // retrieves sweets
  useEffect(() => {
    const fetchProducts = async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/products`);
      setSweets(data.sort((a, b) => a.category.localeCompare(b.category)));
    };
    fetchProducts();
  }, []);

  // retrieves extras
  useEffect(() => {
    const fetchExtras = async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/extras`);
      setExtras(data.sort((a, b) => a.name.localeCompare(b.name)));
    };
    fetchExtras();
  }, []);

  // retrieves sizes
  useEffect(() => {
    const fetchSizes = async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/sizes`);
      setSizes(data.sort((a, b) => a.size - b.size));
    };
    fetchSizes();
  }, []);

  // retrieves baggs
  useEffect(() => {
    const fetchBaggs = async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/baggs`);
      setBaggs(data.sort((a, b) => a.name.localeCompare(b.name)));
    };
    fetchBaggs();
  }, []);

  // adds bagg to variable for enquiry, hides baggs and shows sizes
  const selectBaggHandler = (bagg, image) => {
    setNewBagg(true);
    setTimeout(() => {
      setNewBagg(false);
      dispatchOrder({ type: 'ADD_BAGG', payload: { bagg, image } });
    }, 450);
  };

  // adjusts bagg quantities
  const increaseQuantity = () => {
    dispatchOrder({ type: 'INCREASE_SELECTED_BAGG_QTY' });
  };

  const decreaseQuantity = () => {
    dispatchOrder({ type: 'DECREASE_SELECTED_BAGG_QTY' });
  };

  // adds extra to basket
  const selectExtraHandler = (quantity, stock, name, image, price) => {
    setAdded(true);
    setTimeout(() => {
      setAdded(false);
    }, 3000);
    dispatchOrder({ type: 'ADD_EXTRA', payload: { quantity, stock, name, image, price } });
  };

  // adds size, price & sweet limit amount to selected product, displays enquire button & if custom bagg it will also display sweets
  const selectSizeHandler = (size, price, amount) => {
    if (orderState.baggSelection.sweets.length > amount) {
      setSweetLimitSize(size);
      setVisibleSweetLimit(true);
      return;
    }
    setSweetLimitSize(orderState.baggSelection.size);
    dispatchOrder({ type: 'ADD_SIZE', payload: { size, price, amount } });
  };

  // goes back to select size screen
  const changeSizeHandler = () => {
    dispatchOrder({ type: 'CHANGE_SIZE' });
  };

  // adds sweets to array for enquiry after checking it does not exceed limit
  const selectSweetHandler = (sweet, image) => {
    if (orderState.baggSelection.sweets.length >= orderState.baggSelection.amount) {
      setVisibleSweetLimit(true);
      return;
    }
    if (!orderState.baggSelection.sweets.some((s) => s.sweet === sweet)) {
      dispatchOrder({ type: 'ADD_SWEET', payload: { sweet, image } });
    }
  };

  // removes sweet from selected sweets
  const removeSweetHandler = (sweet) => {
    dispatchOrder({ type: 'REMOVE_SWEET', payload: sweet });
  };

  // adds bagg to basket
  const addToBasketHandler = () => {
    const quantity = orderState.baggSelection.quantity;
    const bagg = orderState.baggSelection.bagg;
    const size = orderState.baggSelection.size;
    if (orderState.baggSelection.sweets.length > orderState.baggSelection.amount) {
      setVisibleSweetLimit(true);
      return;
    }
    if (orderState.baggSelection.bagg === 'Build Your Own' && orderState.baggSelection.sweets.length === 0) {
      setVisibleEmptySweets(true);
      return;
    }
    if (!visibleSweetLimit && !visibleEmptySweets) {
      setAdded(true);
    }
    setTimeout(() => {
      setAdded(false);
    }, 3000);
    setNewBagg(true);
    setTimeout(() => {
      setNewBagg(false);
      dispatchOrder({ type: 'ADD_TO_BASKET', payload: { quantity, bagg, size } });
    }, 450);
  };

  const resetBaggHandler = () => {
    setNewBagg(true);
    setTimeout(() => {
      setNewBagg(false);
      dispatchOrder({ type: 'RESET_BAGG' });
    }, 450);
  };

  return (
    <>
      <h1>Shop</h1>
      {sweets.length === 0 || baggs.length === 0 || sizes.length === 0 ? (
        <Spinner />
      ) : (
        <>
          {added && (
            <div className='notification'>
              <h1>Added to Basket</h1>
            </div>
          )}
          <div className={!newBagg ? 'order' : 'order-new-bagg'}>
            <div className='order-selection'>
              {orderState.baggSelection.bagg && (
                <div>
                  <button className='delete-button' onClick={resetBaggHandler}>
                    Back
                  </button>
                </div>
              )}
              <div className='products-container'>
                {orderState.baggSelection.bagg && (
                  <SelectedBagg
                    name={orderState.baggSelection.bagg}
                    size={orderState.baggSelection.size}
                    image={orderState.baggSelection.image}
                    sweets={orderState.baggSelection.sweets}
                  />
                )}
              </div>
              {orderState.screen.visibleAddBasket && (
                <>
                  <div className='product-controls'>
                    <FaMinusCircle className='controlsIcon' onClick={decreaseQuantity} />
                    <p style={{ margin: '0px 5px' }}>{orderState.baggSelection.quantity}</p>
                    <FaPlusCircle className='controlsIcon' onClick={increaseQuantity} />
                  </div>
                  <div>
                    <h2 className='product-info'>
                      {orderState.baggSelection.price && `£${parseFloat(orderState.baggSelection.price * orderState.baggSelection.quantity).toFixed(2)}`}
                    </h2>
                  </div>
                  <div>
                    <button className='enquire-button' onClick={changeSizeHandler}>
                      Change Size
                    </button>
                    <button className='enquire-button' onClick={addToBasketHandler}>
                      Add to Basket
                    </button>
                  </div>
                </>
              )}
              {orderState.screen.visibleBaggs && <Baggs onSelectBagg={selectBaggHandler} baggs={baggs} />}
              {orderState.screen.visibleExtras && extras.length > 0 && <Extras extras={extras} onSelectExtra={selectExtraHandler} />}
              {orderState.screen.visibleSizes && <Sizes onSelectSize={selectSizeHandler} sizes={sizes} />}
              {orderState.screen.visibleSweets && orderState.baggSelection.sweets.length === 0 && (
                <h2>
                  Select up to <big>{orderState.baggSelection.amount}</big> Sweets
                </h2>
              )}
              {orderState.screen.visibleSweets && orderState.baggSelection.sweets.length > 0 && orderState.baggSelection.bagg === 'Build Your Own' && (
                <h2>
                  {orderState.baggSelection.sweets.length}/<big>{orderState.baggSelection.amount}</big> sweets selected
                </h2>
              )}
              {orderState.baggSelection.sweets.length > 0 && (
                <ul className='products-container'>
                  {orderState.baggSelection.sweets.map((s) => (
                    <SelectedSweets key={s.sweet} name={s.sweet} image={s.image} onRemoveSweet={removeSweetHandler} />
                  ))}
                </ul>
              )}
              {orderState.screen.visibleSweets && <Sweets onSelectSweet={selectSweetHandler} products={sweets} amount={orderState.baggSelection.amount} />}
            </div>
          </div>
          {visibleSweetLimit && <SweetLimit selectedSize={sweetLimitSize} onHideSweetLimit={() => setVisibleSweetLimit(false)} />}
          {visibleEmptySweets && <EmptySweets onHideEmptySweet={() => setVisibleEmptySweets(false)} />}
        </>
      )}
    </>
  );
};

export default Order;
