import React, { useState, useEffect } from 'react';
import axios from 'axios';

import EachSweet from './EachSweet';
import SweetCheckbox from './SweetCheckbox';
import SweetTypeCheckbox from './SweetTypeCheckbox';
import Spinner from '../UI/Spinner';
import { removeFromArray } from '../utils/removeFromArray';

const AllSweets = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [sweets, setSweets] = useState([]);
  const [sweetTypes, setSweetTypes] = useState([]);
  const [filters, setFilters] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState(sweets);

  // retrieves sweets
  useEffect(() => {
    setIsLoading(true);
    const fetchProducts = async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/products`);
      setSweets(data.sort((a, b) => a.category.localeCompare(b.category)));
      setFilteredProducts(data.sort((a, b) => a.category.localeCompare(b.category)));
      setIsLoading(false);
    };
    fetchProducts();
  }, []);

  // filters through sweets to get unique filters
  useEffect(() => {
    let productFilters = sweets.map((sweet) => sweet.category);
    let uniqueFilters = [...new Set(productFilters)].sort();
    setFilters(uniqueFilters);
  }, [sweets]);

  // maps existing products for each unique category
  let productFilters = sweets.map((sweet) => sweet.category);
  let uniqueFilters = [...new Set(productFilters)].sort();

  // filters sweets by filter when filters are updated
  useEffect(() => {
    const filterSweets = () => {
      if (sweetTypes.length === 0) {
        setFilteredProducts(sweets.filter((p) => filters.includes(p.category)));
      } else {
        let filteredByType = [];
        for (let i = 0; i < sweetTypes.length; i++) {
          filteredByType.push(...sweets.filter((p) => p.types.some((t) => t.includes(sweetTypes[i]))));
        }
        setFilteredProducts([...new Set(filteredByType.filter((p) => filters.includes(p.category)))]);
      }
    };
    filterSweets();
  }, [sweets, filters, sweetTypes]);

  // updates checkbox filters and filter array
  const checkFilterHandler = (filter) => {
    if (filters.includes(filter)) {
      let removedFilter = [...new Set(removeFromArray(filters, filter))];
      setFilters(() => removedFilter);
      return;
    }
    if (!filters.includes(filter)) {
      setFilters((filters) => {
        return [...filters, filter];
      });
    }
  };

  // updates checkbox type filters and filter array
  const checkTypeHandler = (type) => {
    if (sweetTypes.includes(type)) {
      let removedFilter = [...new Set(removeFromArray(sweetTypes, type))];
      setSweetTypes(() => removedFilter);
      return;
    }
    if (!sweetTypes.includes(type)) {
      setSweetTypes((sweetTypes) => {
        return [...sweetTypes, type];
      });
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ul className='products-container'>
            {uniqueFilters.sort().map((filter) => (
              <li className='selected-container-filters' key={filter}>
                  <SweetCheckbox filter={filter} checkFilterHandler={checkFilterHandler} />
                  <div className='checkbox-text'>{filter}</div>
              </li>
            ))}
          </ul>
          <ul className='products-container'>
            <li className='gluten-checkbox-container'>
              <SweetTypeCheckbox filter='glutenFree' checkbox={'gf-checkbox'} onCheckType={checkTypeHandler} />
              <div className='checkbox-text'>Gluten Free (GF)</div>
            </li>
            <li className='v-checkbox-container'>
              <SweetTypeCheckbox filter='vegetarian' checkbox={'v-checkbox'} onCheckType={checkTypeHandler} />
              <div className='checkbox-text'>Vegetarian (V)</div>
            </li>
            <li className='v-checkbox-container'>
              <SweetTypeCheckbox filter='vegan' checkbox={'v-checkbox'} onCheckType={checkTypeHandler} />
              <div className='checkbox-text'>Vegan (Vg)</div>
            </li>
          </ul>
          <p>
            Your search has returned <big>{filteredProducts.length}</big> sweets{filteredProducts.length === 0 && ', please try again'}.
          </p>
          <ul className='products-container'>
            {filteredProducts.length > 0 &&
              filteredProducts.map((product) => (
                <EachSweet
                  key={product.name}
                  name={product.name}
                  image={product.imageURL}
                  preview={product.previewImageURL}
                  inStock={product.inStock}
                  types={product.types}
                />
              ))}
          </ul>
        </>
      )}
    </>
  );
};

export default AllSweets;
