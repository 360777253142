import React, { useState, useEffect } from 'react';

import DeleteProduct from './DeleteProduct';
import Card from '../UI/Card';

const RemoveProducts = (props) => {
  const [filters, setFilters] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);

  // maps existing products for each unique category
  let productFilters = props.products.map((product) => product.category);
  const uniqueFilters = [...new Set(productFilters)].sort();

  // sets filters
  const filterCategoryHandler = (event) => {
    const filteredCategory = event.target.value;
    setFilters(filteredCategory);
  };

  // updates filtered products
  useEffect(() => {
    if (filters === 'out-of-stock') {
      return setFilteredProducts(props.products.filter((sweet) => sweet.inStock === false));
    }
    if (filters === 'new-sweets') {
      return setFilteredProducts(props.products.filter((sweet) => sweet.recentlyAdded === true));
    }
    setFilteredProducts(props.products.filter((sweet) => sweet.category.includes(filters)));
  }, [props.products, filters]);

  return (
    <Card className='admin-widgets'>
      <div className='admin-container'>
        <h2>Remove Sweets</h2>
        <div>
          <h3>Filter sweets by category:</h3>
          <select name='category' onChange={filterCategoryHandler}>
            <option value=''>Show All</option>
            <option value='out-of-stock'>Out of Stock</option>
            <option value='new-sweets'>New Sweets</option>
            {uniqueFilters.map((c) => (
              <option key={c} value={c}>
                {c}
              </option>
            ))}
          </select>
        </div>
        <ul>
          <div className='delete-products-container'>
            {filteredProducts.length === 0 ? (
              <p>No sweets have matched this search</p>
            ) : (
              filteredProducts.map((product) => (
                <DeleteProduct
                  key={product._id}
                  id={product._id}
                  name={product.name}
                  image={product.imageURL}
                  preview={product.previewImageURL}
                  category={product.category}
                  recentlyAdded={product.recentlyAdded}
                  inStock={product.inStock}
                  onRefreshProducts={props.onRefreshProducts}
                  categories={props.categories}
                />
              ))
            )}
          </div>
        </ul>
      </div>
    </Card>
  );
};

export default RemoveProducts;
