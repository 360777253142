import React, { useState, useEffect, useReducer } from 'react';
import axios from 'axios';

import Button from '../UI/Button';
import AddCategory from './modals/AddCategory';
import AddSize from './modals/AddSize';
import AddBagg from './modals/AddBagg';
import RemoveCategory from './modals/RemoveCategory';
import RemoveSize from './modals/RemoveSize';
import RemoveBagg from './modals/RemoveBagg';
import { modalReducer } from '../reducers/admin-modal-reducer';

const Edit = ({ filters, onRefreshFilters }) => {
  const [modalState, dispatchModal] = useReducer(modalReducer, {});
  const [sizes, setSizes] = useState([]);
  const [baggs, setBaggs] = useState([]);
  const [baggResponse, setBaggResponse] = useState(null);
  const [sizeResponse, setSizeResponse] = useState(null);

  // retrieves sizes
  useEffect(() => {
    const fetchSizes = async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/sizes`);
      setSizes(data.sort((a, b) => a.distance - b.distance));
    };
    fetchSizes();
  }, [sizeResponse]);

  // retrieves baggs
  useEffect(() => {
    const fetchBaggs = async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/baggs`);
      setBaggs(data);
    };
    fetchBaggs();
  }, [baggResponse]);

  // triggers another fetch sizes if one is added or removed
  const refreshSizeData = (res) => {
    setSizeResponse(res);
  };

  // triggers another fetch baggs if one is added or removed
  const refreshBaggData = (res) => {
    setBaggResponse(res);
  };

  return (
    <>
      <h2>Categories, Bags &amp; Sizes</h2>
      <div className='modal-text'>
        <div className='admin-container'>
          <h3>Categories</h3>
          <Button onClick={() => dispatchModal({ type: 'SHOW_ADD_CATEGORY' })}>Add</Button>
          <Button className='remove-button' onClick={() => dispatchModal({ type: 'SHOW_REMOVE_CATEGORY' })}>
            Remove
          </Button>
        </div>
        <div className='admin-container'>
          <h3>Bags</h3>
          <Button onClick={() => dispatchModal({ type: 'SHOW_ADD_BAGG' })}>Add</Button>
          <Button className='remove-button' onClick={() => dispatchModal({ type: 'SHOW_REMOVE_BAGG' })}>
            Remove
          </Button>
        </div>
        <div className='admin-container'>
          <h3>Sizes</h3>
          <Button onClick={() => dispatchModal({ type: 'SHOW_ADD_SIZE' })}>Add</Button>
          <Button className='remove-button' onClick={() => dispatchModal({ type: 'SHOW_REMOVE_SIZE' })}>
            Remove
          </Button>
        </div>
      </div>
      {modalState.showAddCategory && (
        <AddCategory filters={filters} onClose={() => dispatchModal({ type: 'HIDE_MODAL' })} onRefreshFilters={onRefreshFilters} />
      )}
      {modalState.showRemoveCategory && (
        <RemoveCategory filters={filters} onClose={() => dispatchModal({ type: 'HIDE_MODAL' })} onRefreshFilters={onRefreshFilters} />
      )}
      {modalState.showAddBagg && (
        <AddBagg baggs={baggs} onClose={() => dispatchModal({ type: 'HIDE_MODAL' })} onBaggPost={refreshBaggData} />
      )}
      {modalState.showRemoveBagg && (
        <RemoveBagg baggs={baggs} onClose={() => dispatchModal({ type: 'HIDE_MODAL' })} onBaggDelete={refreshBaggData} />
      )}
      {modalState.showAddSize && (
        <AddSize sizes={sizes} onClose={() => dispatchModal({ type: 'HIDE_MODAL' })} onSizePost={refreshSizeData} />
      )}
      {modalState.showRemoveSize && (
        <RemoveSize sizes={sizes} onClose={() => dispatchModal({ type: 'HIDE_MODAL' })} onSizeDelete={refreshSizeData} />
      )}
    </>
  );
};

export default Edit;
