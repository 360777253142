import React, { useState, useContext } from 'react';
import axios from 'axios';

import ConfirmEventComplete from './modals/ConfirmEventComplete';
import ConfirmEventCancel from './modals/ConfirmEventCancel';
import Button from '../UI/Button';
import { AuthContext } from '../context/auth-context';
import { dayFormatter } from '../utils/dayFormatter';
import { dateFormatter } from '../utils/dateFormatter';
import { timeFormatter } from '../utils/timeFormatter';

const AdminEventRequests = (props) => {
  const auth = useContext(AuthContext);

  const [completedEvent, setCompletedEvent] = useState({});
  const [cancelledEvent, setCancelledEvent] = useState({});
  const [confirmEventComplete, setConfirmEventComplete] = useState(false);
  const [confirmEventCancel, setConfirmEventCancel] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // updates responded checkbox
  const updateResponded = async (request) => {
    if (request.responded === false) {
      try {
        await axios
          .put(
            `${process.env.REACT_APP_BACKEND_URL}/api/event-enquiry/responded/${request._id}`,
            { responded: true },
            { headers: { Authorization: 'Bearer ' + auth.userData.token } }
          )
          .then((res) => {
            props.onUpdate(res.data);
          });
      } catch (err) {
        setError(true);
        setErrorMessage(err.response.data.message);
        setTimeout(() => setError(false), 2000);
      }
    } else {
      try {
        await axios
          .put(
            `${process.env.REACT_APP_BACKEND_URL}/api/event-enquiry/responded/${request._id}`,
            { responded: false },
            { headers: { Authorization: 'Bearer ' + auth.userData.token } }
          )
          .then((res) => {
            props.onUpdate(res.data);
          });
      } catch (err) {
        setError(true);
        setErrorMessage(err.response.data.message);
        setTimeout(() => setError(false), 2000);
      }
    }
  };

  // displays modal and passes data to it
  const completeEventHandler = (request) => {
    setConfirmEventComplete(true);
    setCompletedEvent({
      request,
    });
  };

  // displays modal and passes data to it
  const cancelEventHandler = (request) => {
    setConfirmEventCancel(true);
    setCancelledEvent({
      request,
    });
  };

  return (
    <>
      {props.eventRequests.length === 0 && <p>You have no event requests!</p>}
      {props.eventRequests.length > 1 && (
        <h3>
          You have <big>Event</big> requests!
        </h3>
      )}
      {props.eventRequests.length === 1 && (
        <h3>
          You have an <big>Event</big> request!
        </h3>
      )}
      {props.eventRequests.length > 0 && (
        <div className='table-container'>
          <table>
            <thead>
              <tr>
                <th>Submitted</th>
                <th>Name &amp; Email Address</th>
                <th>Occasion</th>
                <th>Date</th>
                <th>Additional Info</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {props.eventRequests.map((request) => (
                <tr key={request._id}>
                  <td>
                    {dateFormatter(request.submittedOn)}
                    <br />
                    {timeFormatter(request.submittedOn)}
                    <br />
                    <button className='delete-button' onClick={() => cancelEventHandler(request)}>
                      Cancel
                    </button>
                  </td>
                  <td>
                    <b>{request.name}</b>
                    <br />
                    {request.email}
                  </td>
                  <td>{request.occasion}</td>
                  <td>
                    {dayFormatter(request.submittedOn)}
                    <br />
                    {dateFormatter(request.date)}
                  </td>
                  <td>{request.specialRequirements ? request.specialRequirements : 'N/A'}</td>
                  <td>
                    <input
                      type='checkbox'
                      className='checkbox'
                      checked={request.responded === true ? 'checked' : ''}
                      onChange={() => updateResponded(request)}
                    />
                    <br />
                    Responded
                    {request.responded === true && (
                      <>
                        <p>
                          {dateFormatter(request.respondedDate)}
                          <br />
                          {timeFormatter(request.respondedDate)}
                        </p>
                        <hr />
                        <Button onClick={() => completeEventHandler(request)}>Complete</Button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {error && (
        <div className='error-modal-text'>
          <h3>{errorMessage}</h3>
        </div>
      )}
      {confirmEventComplete && (
        <ConfirmEventComplete onHide={() => setConfirmEventComplete(false)} request={completedEvent.request} onUpdate={props.onUpdate} />
      )}
      {confirmEventCancel && (
        <ConfirmEventCancel onHide={() => setConfirmEventCancel(false)} request={cancelledEvent.request} onUpdate={props.onUpdate} />
      )}
    </>
  );
};

export default AdminEventRequests;
