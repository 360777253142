import React from 'react';

import Button from '../UI/Button';

const BaggItem = (props) => {
  const selectBaggHandler = () => {
    props.onSelectBagg(props.name, props.preview);
  };

  return (
    <li className='product-container'>
      <div className='product-image'>
        <img src={props.preview} alt={props.name} className='image' />
      </div>
      <div>
        <Button onClick={selectBaggHandler}>{props.name}</Button>
      </div>
      <div className='shop-product__info-container'>{props.description}</div>
    </li>
  );
};

export default BaggItem;
