import React from 'react';

import Modal from '../../UI/Modal';

const FullSizeSweet = (props) => {
  return (
    <Modal onClose={props.onClose}>
      <div className='modal-title'>
        <button className='exit-button' onClick={props.onClose}>
          X
        </button>
        <h2>{props.name}</h2>
      </div>
      <div className='modal-text'>
        <img className='full-size-image' src={props.image} alt={props.name} />
      </div>
    </Modal>
  );
};

export default FullSizeSweet;
