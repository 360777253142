import React, { useState } from 'react';
import axios from 'axios';

import Modal from '../../UI/Modal';
import Button from '../../UI/Button';
import { dateFormatter } from '../../utils/dateFormatter';

const EventEnquiry = (props) => {
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const name = props.name;
  const email = props.email;
  const occasion = props.occasion;
  const date = props.date;
  const specialRequirements = props.specialRequirements;

  const confirmEnquiryHandler = () => {
    const sendData = async () => {
      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/event-enquiry`, { name, email, occasion, date, specialRequirements });
        setSubmitted(true);
        props.onResetValues();
      } catch (err) {
        setSubmitted(false);
        setError(true);
        setErrorMessage(err.response.data.message);
      }
    };
    sendData();
  };

  return (
    <Modal onClose={props.onClose}>
      <div className='modal-title'>
        <h2>Confirm your enquiry</h2>
      </div>
      {!submitted && (
        <>
          <div className='modal-text'>
            <p>
              <b>{props.name}</b>,
              <br />
              You would like to enquire for your <b>{props.occasion}</b> event on
              <br />
              <b>{dateFormatter(props.date)}</b>.<br />
              We will be in touch via <b>{props.email}</b> to ask about your requirements.
            </p>
          </div>
          {props.specialRequirements && (
            <div className='modal-text'>
              <p>
                <b>Additional information:</b>
                <br />
                {props.specialRequirements}
              </p>
            </div>
          )}
        </>
      )}
      {submitted && (
        <div className='modal-text'>
          <h3>Enquiry sent successfully</h3>
        </div>
      )}
      {error && (
        <div className='error-modal-text'>
          <h3>{errorMessage}</h3>
        </div>
      )}
      <div className='modal-actions'>
        <Button className='remove-button' onClick={props.onClose}>
          Close
        </Button>
        {!submitted && <Button onClick={confirmEnquiryHandler}>Send Enquiry</Button>}
      </div>
    </Modal>
  );
};

export default EventEnquiry;
