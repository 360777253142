import React, { useState, useContext } from 'react';
import axios from 'axios';

import Button from '../../UI/Button';
import Spinner from '../../UI/Spinner';
import { AuthContext } from '../../context/auth-context';
import { convertToKilos } from '../../utils/convertToKilos';

const EditSize = (props) => {
  const auth = useContext(AuthContext);

  const [sizeInput, setSizeInput] = useState(props.size.size);
  const [amountInput, setAmountInput] = useState(props.size.amount);
  const [priceInput, setPriceInput] = useState(props.size.price);
  const [isLoading, setIsLoading] = useState(false);
  const [edited, setEdited] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // edit size submit function
  const editSize = async (size, price, amount) => {
    setIsLoading(true);
    try {
      await axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/api/sizes/${props.size.id}`,
          { size, price, amount },
          { headers: { Authorization: 'Bearer ' + auth.userData.token } }
        )
        .then((res) => {
          props.onSizeEdit(res.data);
          setEdited(true);
          setIsLoading(false);
          setTimeout(() => setEdited(false), 2000);
        });
    } catch (err) {
      setError(true);
      setErrorMessage(err.response.data.message);
      setIsLoading(false);
      setTimeout(() => setError(false), 2000);
    }
  };

  // edits size
  const editSizeHandler = () => {
    const newSize = parseFloat(sizeInput);
    const newAmount = parseFloat(amountInput);
    const newPrice = parseFloat(priceInput);
    editSize(newSize, newPrice, newAmount);
  };

  const sizeChangeHandler = (event) => {
    setSizeInput(event.target.value);
  };

  const amountChangeHandler = (event) => {
    setAmountInput(event.target.value);
  };

  const priceChangeHandler = (event) => {
    setPriceInput(event.target.value);
  };

  return (
    <>
      <div className='modal-text'>
        <div className='edit'>
          <label htmlFor='size'>Weight (g)</label>
          <input id='size' type='text' value={sizeInput} size='8' maxLength='5' onChange={sizeChangeHandler} />
        </div>
        <div className='edit'>
          <label htmlFor='amount'>Max. sweet limit</label>
          <input id='amount' type='text' value={amountInput} size='8' maxLength='5' onChange={amountChangeHandler} />
        </div>
        <div className='edit'>
          <label htmlFor='price'>Price (£)</label>
          <input id='price' type='text' value={priceInput.toFixed(2)} size='8' maxLength='6' onChange={priceChangeHandler} />
        </div>
      </div>
      {edited && (
        <div className='modal-text'>
          <h3>'{convertToKilos(sizeInput)} bag' edited successfully!</h3>
        </div>
      )}
      {error && (
        <div className='error-modal-text'>
          <h3>{errorMessage}</h3>
        </div>
      )}
      <div className='modal-actions'>
        {!edited && !isLoading && (
          <Button className='remove-button' onClick={props.onBack}>
            Back
          </Button>
        )}
        {isLoading ? <Spinner /> : !edited && <Button onClick={editSizeHandler}>Edit Size</Button>}
      </div>
    </>
  );
};

export default EditSize;
