import React, { useState, useContext } from 'react';
import Button from '../UI/Button';
import { FaMinusCircle } from 'react-icons/fa';
import { FaPlusCircle } from 'react-icons/fa';

import FullSizeSweet from './modals/FullSizeSweet';
import { BasketContext } from '../context/basket-context';

const ExtraItem = (props) => {
  const { orderState } = useContext(BasketContext);

  const [visibleFullSize, setVisibleFullSize] = useState(false);
  const [visibleAdded, setVisibleAdded] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [stockLimit, setStockLimit] = useState(false);

  const counts = {};
  const extraCounter = () => {
    orderState.basket.extras.forEach((x) => {
      counts[x.name] = (counts[x.name] || 0) + 1;
    });
  };
  extraCounter();

  const displayFullSize = () => {
    setVisibleFullSize(true);
  };

  const hideFullSize = () => {
    setVisibleFullSize(false);
  };

  const increaseQuantity = () => {
    let inBasket = counts[props.name] || 0;
    let stock = props.stockCount - inBasket;

    if (!visibleAdded && stock - quantity > 0 && quantity < 50) {
      setQuantity((quantity) => quantity + 1);
    }
    if (!visibleAdded && stock - quantity === 0) {
      setStockLimit(true);
      setTimeout(() => {
        setStockLimit(false);
      }, 2000);
    }
    if (!visibleAdded && quantity === 50) {
      setStockLimit(true);
      setTimeout(() => {
        setStockLimit(false);
      }, 2000);
    }
  };

  const decreaseQuantity = () => {
    if (!visibleAdded && quantity > 1) {
      setQuantity((quantity) => quantity - 1);
    }
  };

  const selectExtraHandler = () => {
    let stock = props.stockCount;
    props.onSelectExtra(quantity, stock, props.name, props.preview, props.price);
    setVisibleAdded(true);
    setTimeout(() => {
      setQuantity(1);
      setVisibleAdded(false);
    }, 2000);
  };

  return (
    <li className='product-container'>
      <div className='product-image'>
        <div className='sweet-image-hover-container' onClick={displayFullSize}>
          <img src={props.preview} alt={props.name} className={props.stockCount > 0 ? 'sweet-image' : 'out-of-stock'} />
          {!props.stockCount > 0 ? (
            <div className='img-centered-text'>
              <b>Out of Stock</b>
            </div>
          ) : null}
          {props.types.some((t) => t.includes('glutenFree')) && <div className='img-gluten-text'>GF</div>}
          {!props.types.some((t) => t.includes('vegan')) && props.types.some((t) => t.includes('vegetarian')) && <div className='img-veggie-text'>V</div>}
          {props.types.some((t) => t.includes('vegan')) && <div className='img-vegan-text'>Vg</div>}
        </div>
      </div>
      <div className='product-name'>
        <p>{props.name}</p>
      </div>
      <h3 className='product-info'>{`£${parseFloat(props.price).toFixed(2)}`}</h3>
      <div className='product-controls'>
        <FaMinusCircle className='controlsIcon' onClick={decreaseQuantity} />
        <p style={{ margin: '0px 5px' }}>{quantity}</p>
        <FaPlusCircle className='controlsIcon' onClick={increaseQuantity} />
      </div>
      <div className='product-name'>
        {props.stockCount > 0 && !visibleAdded && !stockLimit && <Button onClick={!visibleAdded && selectExtraHandler}>Add To Basket</Button>}
        {props.stockCount > 0 && visibleAdded && (
          <h4 className='product-info'>
            {quantity} Product{quantity > 1 && 's'} Added
          </h4>
        )}
        {props.stockCount === 0 && <p className='product-info'>Unavailable</p>}
        {stockLimit && <p className='product-info'>Maximum Amount Added</p>}
      </div>

      {visibleFullSize && <FullSizeSweet onClose={hideFullSize} name={props.name} image={props.image} />}
    </li>
  );
};

export default ExtraItem;
