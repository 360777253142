import React from 'react';

import BaggItem from './BaggItem';

const Baggs = (props) => {
  return (
    <div>
      <h2>Pick and Mix Bags</h2>
      <ul className='products-container'>
        {props.baggs.map((bagg) => (
          <BaggItem
            key={bagg._id}
            name={bagg.name}
            description={bagg.description}
            image={bagg.imageURL}
            preview={bagg.previewImageURL}
            onSelectBagg={props.onSelectBagg}
          />
        ))}
        <BaggItem
          name='Build Your Own'
          description='Choose your own custom sweets'
          image='https://baggsofsweets-dev-images.s3.eu-west-2.amazonaws.com/custom-bagg.jpeg'
          preview='https://baggsofsweets-dev-thumbnails.s3.eu-west-2.amazonaws.com/custom-bagg.jpeg'
          onSelectBagg={props.onSelectBagg}
        />
      </ul>
    </div>
  );
};

export default Baggs;
