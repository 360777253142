import React from 'react';

import SizeItem from './SizeItem';

const Sizes = (props) => {
  return (
    <ul className='products-container'>
      {props.sizes.map((size) => (
        <SizeItem key={size.size} size={size.size} price={size.price} amount={size.amount} onSelectSize={props.onSelectSize} />
      ))}
    </ul>
  );
};

export default Sizes;
