import React, { useState, useEffect, useContext } from 'react';

import SelectedProduct from '../components/SelectedProduct';
import ConfirmOrder from '../components/modals/ConfirmOrder';
import Button from '../UI/Button';
import { BasketContext } from '../context/basket-context';

const Basket = () => {
  const { orderState, dispatchOrder } = useContext(BasketContext);
  const [removed, setRemoved] = useState(false);
  const [totalPrice, setTotalPrice] = useState();
  const [visibleConfirmOrder, setVisibleConfirmOrder] = useState(false);

  let uniqueExtras = [...new Map(orderState.basket.extras.map((item) => [item['name'], item])).values()];

  const counts = {};
  const extraCounter = () => {
    orderState.basket.extras.forEach((x) => {
      counts[x.name] = (counts[x.name] || 0) + 1;
    });
  };
  extraCounter();

  const removeBaggFromBasketHandler = (bagg) => {
    dispatchOrder({ type: 'REMOVE_BAGG_FROM_BASKET', payload: bagg });
  };

  const removeExtraFromBasketHandler = (extra) => {
    dispatchOrder({ type: 'REMOVE_EXTRA_FROM_BASKET', payload: extra });
  };

  const startAgain = () => {
    setRemoved(true);
    setTimeout(() => {
      dispatchOrder({ type: 'START_AGAIN' });
      localStorage.removeItem('basket');
    }, 900);
  };

  const confirmOrder = () => {
    setVisibleConfirmOrder(true);
  };

  useEffect(() => {
    const total =
      orderState.basket.baggs.reduce((total, currentItem) => (total = total + currentItem.quantity * currentItem.price), 0) +
      orderState.basket.extras.reduce((total, currentItem) => (total = total + currentItem.price), 0);
    setTotalPrice(total);
  }, [orderState.basket.baggs, orderState.basket.extras]);

  return (
    <>
      <h1>Basket</h1>
      {orderState.basket.baggs.length === 0 && orderState.basket.extras.length === 0 ? (
        <h3>Your basket is empty</h3>
      ) : (
        <div className={removed ? 'basket-removed' : null}>
          <SelectedProduct
            onRemoveBagg={removeBaggFromBasketHandler}
            onRemoveExtra={removeExtraFromBasketHandler}
            onStartAgain={startAgain}
          />
          <div className='selected-product'>
            {orderState.basket.baggs.length + orderState.basket.extras.length >= 1 && (
              <h2 className='product-info'>Total: £{parseFloat(totalPrice).toFixed(2)}</h2>
            )}
            <Button className='enquire-button' onClick={confirmOrder}>
              Order now
            </Button>
          </div>
        </div>
      )}
      {visibleConfirmOrder && (
        <ConfirmOrder
          onHideConfirmOrder={() => setVisibleConfirmOrder(false)}
          basket={orderState.basket}
          extras={uniqueExtras}
          counts={counts}
          onRemoveBagg={removeBaggFromBasketHandler}
          onRemoveExtra={removeExtraFromBasketHandler}
          totalPrice={totalPrice}
          onStartAgain={startAgain}
        />
      )}
    </>
  );
};

export default Basket;
