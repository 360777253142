import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import AddProduct from '../components/AddProduct';
import AddExtra from './AddExtra';
import Edit from '../components/Edit';
import RemoveProducts from '../components/RemoveProducts';
import RemoveExtras from './RemoveExtras';
import Card from '../UI/Card';
import { AuthContext } from '../context/auth-context';

const AdminProducts = () => {
  const auth = useContext(AuthContext);

  const [filters, setFilters] = useState([]);
  const [productResponse, setProductResponse] = useState(null);
  const [extraResponse, setExtraResponse] = useState(null);
  const [filterResponse, setFilterResponse] = useState(null);
  const [sweets, setSweets] = useState([]);
  const [extras, setExtras] = useState([]);

  // retrieves sweets
  useEffect(() => {
    const fetchProducts = async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/products`);
      setSweets(data.sort((a, b) => a.category.localeCompare(b.category)));
    };
    fetchProducts();
  }, [productResponse]);
  // retrieves extras
  useEffect(() => {
    const fetchProducts = async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/extras`);
      setExtras(data.sort((a, b) => a.name.localeCompare(b.name)));
    };
    fetchProducts();
  }, [extraResponse]);
  // retrieves filters
  useEffect(() => {
    const fetchFilters = async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/filters`, {
        headers: { Authorization: 'Bearer ' + auth.userData.token },
      });
      setFilters(data.sort((a, b) => a.filter.localeCompare(b.filter)));
    };
    fetchFilters();
  }, [filterResponse, auth.userData.token]);

  // triggers another fetch products if one is added or removed
  const refresProductData = (res) => {
    setProductResponse(res);
  };
  // triggers another fetch extras if one is added or removed
  const refreshExtraData = (res) => {
    setExtraResponse(res);
  };
  // triggers another fetch filters if one is added or removed
  const refreshFilterData = (res) => {
    setFilterResponse(res);
  };

  return (
    <>
      <h2>Products</h2>
      <div className='admin-widgets'>
        <Card className='admin-card'>
          <AddProduct filters={filters} products={sweets} onRefreshProducts={refresProductData} />
        </Card>
        <Card className='admin-card'>
          <AddExtra extras={extras} onRefreshExtras={refreshExtraData} />
        </Card>
        <Card className='admin-card'>
          <Edit filters={filters} onRefreshFilters={refreshFilterData} />
        </Card>
        <RemoveExtras products={extras} onRefreshExtras={refreshExtraData} />
        <RemoveProducts
          filters={filters}
          products={sweets}
          onRefreshProducts={refresProductData}
          onRefreshFilters={refreshFilterData}
          categories={filters}
        />
      </div>
    </>
  );
};

export default AdminProducts;
