import React from 'react';

import { convertToKilos } from '../utils/convertToKilos';

const SelectedBagg = (props) => {
  return (
    <li className='selected-product'>
      <div className='selected-container'>
        <img src={props.image} alt={props.name} className='image' />
      </div>
      <div className='basket-product-container'>
        <h3>
          {props.size && <>{convertToKilos(props.size)}</>}{' '}
          {props.name}
        </h3>
        {props.name === 'Build Your Own' && props.sweets.length > 0 && (
          <>
            <p>
              <b>With:</b>
            </p>
            <ul>
              {props.sweets.map((s) => (
                <React.Fragment key={s.sweet}>
                  {s.sweet}
                  <br />
                </React.Fragment>
              ))}
            </ul>
          </>
        )}
      </div>
    </li>
  );
};

export default SelectedBagg;
