import React from 'react';

import Modal from '../../UI/Modal';
import Button from '../../UI/Button';

const EmptySweets = (props) => {
  return (
    <Modal onClose={props.onHideEmptySweet}>
      <div className='modal-title'>
        <h2>No sweets selected!</h2>
      </div>
      <div className='modal-text'>
        <p>Please select the sweets you would like to add to your Build Your Own bag.</p>
      </div>
      <div className='modal-actions'>
        <Button onClick={props.onHideEmptySweet}>Okay</Button>
      </div>
    </Modal>
  );
};

export default EmptySweets;
