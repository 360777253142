import React, { useRef, useState, useEffect } from "react";

import Button from "./Button";

const ImageUpload = (props) => {
  const [file, setFile] = useState();
  const [previewUrl, setPreviewUrl] = useState();

  const filePickerRef = useRef();

  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  useEffect(() => {
    deletePickedImageHandler();
  }, [props.onDelete]);

  const pickImageHandler = () => {
    filePickerRef.current.click();
  };

  const selectImageHandler = (event) => {
    let pickedFile;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      props.onSelectImage(pickedFile);
      setFile(pickedFile);
    } else {
      return;
    }
  };

  const deletePickedImageHandler = () => {
    setFile(null);
    setPreviewUrl(null);
    filePickerRef.current.value = "";
  };

  return (
    <div>
      <input id={props.id} ref={filePickerRef} type="file" style={{ display: "none" }} accept=".jpg,.png,.jpeg" onChange={selectImageHandler} />
      <div>
        {previewUrl ? (
          <div>
            <div className="image-upload-preview">
              <img src={previewUrl} alt={"Preview"} />
            </div>
            <button className="delete-button" onClick={deletePickedImageHandler}>
              X
            </button>
          </div>
        ) : (
          <div>
            <Button type="button" onClick={pickImageHandler}>
              Choose Image
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageUpload;
