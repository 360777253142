import React, { useState } from 'react';

import Modal from '../../UI/Modal';
import Button from '../../UI/Button';
import RemoveBaggItem from './modalItems/RemoveBaggItem';

const RemoveBagg = (props) => {
  const [removed, setRemoved] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <Modal onClose={props.onClose}>
      <div className='modal-title'>
        <h2>Remove bag</h2>
      </div>
      <div className='modal-body'>
        <ul className='modal-text'>
          {props.baggs.length > 0 ? (
            props.baggs.map((b) => (
              <RemoveBaggItem
                key={b._id}
                id={b._id}
                name={b.name}
                image={b.imageURL}
                preview={b.previewImageURL}
                recentlyAdded={b.recentlyAdded}
                onBaggDelete={props.onBaggDelete}
                onRemove={setRemoved}
                onError={setError}
                onErrorMessage={setErrorMessage}
              />
            ))
          ) : (
            <p>You have no bags.</p>
          )}
        </ul>
        {removed && (
          <div className='modal-text'>
            <h3>Bag removed!</h3>
          </div>
        )}
        {error && (
          <div className='error-modal-text'>
            <h3>{errorMessage}</h3>
          </div>
        )}
        <div className='modal-actions'>
          <Button className='remove-button' onClick={props.onClose}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveBagg;
