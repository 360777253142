import React, { useState } from 'react';

import Modal from '../../UI/Modal';
import Button from '../../UI/Button';
import RemoveCategoryItem from './modalItems/RemoveCategoryItem';

const RemoveCategory = (props) => {
  const [removed, setRemoved] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <Modal onClose={props.onClose}>
      <div className='modal-title'>
        <h2>Remove category</h2>
      </div>
      <ul className='modal-text'>
        {props.filters ? (
          props.filters.map((f) => (
            <RemoveCategoryItem
              key={f._id}
              id={f._id}
              filter={f.filter}
              onRefreshFilters={props.onRefreshFilters}
              onRemove={setRemoved}
              onError={setError}
              onErrorMessage={setErrorMessage}
            />
          ))
        ) : (
          <p>You have no categories.</p>
        )}
      </ul>
      {removed && (
        <div className='modal-text'>
          <h3>Category removed!</h3>
        </div>
      )}
      {error && (
        <div className='error-modal-text'>
          <h3>{errorMessage}</h3>
        </div>
      )}
      <div className='modal-actions'>
        <Button className='remove-button' onClick={props.onClose}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default RemoveCategory;
