import React from 'react';

const ConfirmOrderExtraItem = (props) => {
  // removes item from basket
  const removeExtraHandler = () => {
    props.onRemoveExtra(props.name);
  };

  return (
    <div>
      <div className='enquiry-item'>
        <div>
          <p>
            <b>{props.quantity}</b> x <b>{props.name}</b>
          </p>
        </div>
        <button className='delete-button' onClick={removeExtraHandler}>
          X
        </button>
      </div>
      <hr />
    </div>
  );
};

export default ConfirmOrderExtraItem;
