import React, { createContext, useReducer, useEffect } from 'react';
import { orderReducer } from '../reducers/order-reducer';

export const BasketContext = createContext();

const orderInitialState = {
  basket: { baggs: [], extras: [] },
  baggSelection: { sweets: [] },
  screen: { visibleBaggs: true, visibleExtras: true },
};

const BasketContextProvider = (props) => {
  const [orderState, dispatchOrder] = useReducer(orderReducer, orderInitialState, () => {
    const storedBasket = localStorage.getItem('Basket');
    return storedBasket ? { ...orderInitialState, basket: JSON.parse(storedBasket) } : orderInitialState;
  });

  useEffect(() => {
    localStorage.setItem('Basket', JSON.stringify(orderState.basket));
  }, [orderState.basket]);
  // useEffect(() => {
  //   console.log(orderState.baggSelection);
  // }, [orderState.baggSelection]);
  return <BasketContext.Provider value={{ orderState, dispatchOrder }}>{props.children}</BasketContext.Provider>;
};

export default BasketContextProvider;
