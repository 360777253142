import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Card from './UI/Card';
import Header from './UI/Header';
import Home from './pages/Home';
import ViewSweets from './pages/ViewSweets';
import Order from './pages/Order';
import Contact from './pages/Contact';
import Admin from './pages/Admin';
import Basket from './pages/Basket';
import { AuthContext } from './context/auth-context';
import BasketContextProvider from './context/basket-context';
import { useAuth } from './hooks/auth-hook';

const App = () => {
  const { token, login, logout, userData, loggedOut } = useAuth();

  return (
    <AuthContext.Provider value={{ isLoggedIn: !!token, userData, login, logout }}>
      <BasketContextProvider>
        <div className='App'>
          <Header />
          <div className='body'>
            <Card className='body-card'>
              <Routes>
                <Route path='/*' element={<Home />} />
                <Route path='/sweets' element={<ViewSweets />} />
                <Route path='/order' element={<Order />} />
                <Route path='/basket' element={<Basket />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/admin' element={<Admin loggedOut={loggedOut} />} />
              </Routes>
            </Card>
            <p>
              This website and its content is copyright of Baggs Of Sweets<sup>&copy;</sup> {new Date().getFullYear()}.&nbsp;All rights
              reserved.
            </p>
          </div>
        </div>
      </BasketContextProvider>
    </AuthContext.Provider>
  );
};

export default App;
