import { createContext } from 'react';

export const AuthContext = createContext({
  isLoggedIn: false,
  userData: {
    userId: null,
    name: null,
    isAdmin: false,
    token: null,
  },
  login: () => {},
  logout: () => {},
});
