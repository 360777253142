import React, { useContext } from 'react';
import axios from 'axios';

import { AuthContext } from '../../../context/auth-context';
import { convertToKilos } from '../../../utils/convertToKilos';

import Button from '../../../UI/Button';

const RemoveSizeItem = (props) => {
  const auth = useContext(AuthContext);

  // passes info to edit size
  const editHandler = () => {
    props.onSizeEdit(props.id, props.size, props.amount, props.price);
  };

  // deletes one size
  const deleteSizeHandler = () => {
    const deleteSize = async () => {
      try {
        await axios
          .delete(`${process.env.REACT_APP_BACKEND_URL}/api/sizes/${props.id}`, {
            headers: { Authorization: 'Bearer ' + auth.userData.token },
          })
          .then((res) => {
            props.onSizeDelete(res.data);
            props.onRemove(true);
            setTimeout(() => props.onRemove(false), 2000);
          });
      } catch (err) {
        props.onError(true);
        props.onErrorMessage(err.response.data.message);
        setTimeout(() => props.onError(false), 2000);
      }
    };
    deleteSize();
  };

  return (
    <div className='modal-item-larger'>
      <div className='modal-item__item'>
        <li>
          <h3>{convertToKilos(props.size)}</h3>
          <p>Up to {props.amount} sweets</p>
          <p>£{parseFloat(props.price).toFixed(2)}</p>
        </li>
      </div>
      <div className='modal-item__button'>
        <Button onClick={editHandler}>Edit</Button>
        <button className='delete-button' onClick={deleteSizeHandler}>
          X
        </button>
      </div>
    </div>
  );
};

export default RemoveSizeItem;
