import React, { useContext } from 'react';
import axios from 'axios';

import { AuthContext } from '../../../context/auth-context';

const RemoveCategoryItem = (props) => {
  const auth = useContext(AuthContext);

  // deletes one size
  const deleteCategoryHandler = () => {
    const deleteFilter = async () => {
      try {
        await axios
          .delete(`${process.env.REACT_APP_BACKEND_URL}/api/filters/${props.id}`, {
            headers: { Authorization: 'Bearer ' + auth.userData.token },
          })
          .then((res) => {
            props.onRefreshFilters(res.data);
            props.onRemove(true);
            setTimeout(() => props.onRemove(false), 2000);
          });
      } catch (err) {
        props.onError(true);
        props.onErrorMessage(err.response.data.message);
        setTimeout(() => props.onError(false), 2000);
      }
    };
    deleteFilter();
  };

  return (
    <div key={props.id} className='modal-item'>
      <div className='modal-item__item'>
        <li>{props.filter}</li>
      </div>
      <div className='modal-item__button'>
        <button className='delete-button' onClick={deleteCategoryHandler}>
          X
        </button>
      </div>
    </div>
  );
};

export default RemoveCategoryItem;
