import React, { useContext } from 'react';

import AdminLoggedIn from '../components/AdminLoggedIn';
import AdminLogin from '../components/AdminLogin';
import { AuthContext } from '../context/auth-context';
import Button from '../UI/Button';

const Admin = (props) => {
  const auth = useContext(AuthContext);

  const logoutHandler = () => {
    auth.logout();
  };

  return (
    <>
      {props.loggedOut && <h3>You have been logged out.</h3>}
      {auth.isLoggedIn && auth.userData.isAdmin && (
        <>
          <AdminLoggedIn />
          <Button onClick={logoutHandler}>Logout</Button>
        </>
      )}
      {!auth.isLoggedIn && !auth.userData.isAdmin && <AdminLogin />}
    </>
  );
};

export default Admin;
