import React, { useContext } from 'react';

import { BasketContext } from '../context/basket-context';
import Button from '../UI/Button';

const Sweet = (props) => {
  const { orderState } = useContext(BasketContext);

  const selectSweetHandler = () => {
    props.onSelectSweet(props.name, props.image);
  };

  return (
    <li className='product-container'>
      <div className='product-image'>
        <div className='sweet-image-container'>
          <img src={props.image} alt={props.name} className={props.inStock ? 'image' : 'out-of-stock'} style={{ cursor: 'default' }} />
          {!props.inStock ? (
            <div className='img-centered-text'>
              <b>Out of Stock</b>
            </div>
          ) : null}
          {props.types.some((t) => t.includes('glutenFree')) && <div className='img-gluten-text'>GF</div>}
          {!props.types.some((t) => t.includes('vegan')) && props.types.some((t) => t.includes('vegetarian')) && <div className='img-veggie-text'>V</div>}
          {props.types.some((t) => t.includes('vegan')) && <div className='img-vegan-text'>Vg</div>}
        </div>
      </div>
      <div className='product-name'>
        <p>{props.name}</p>
      </div>
      <div className='product-name' style={{ height: '40px' }}>
        {props.inStock && !orderState.baggSelection.sweets.some((s) => s.sweet === props.name) && <Button onClick={selectSweetHandler}>Add</Button>}
        {!props.inStock && <p className='product-info'>Unavailable</p>}
        {props.inStock && orderState.baggSelection.sweets.some((s) => s.sweet === props.name) && <p className='product-info'>Selected</p>}
      </div>
    </li>
  );
};
export default Sweet;
