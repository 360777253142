import { useState, useCallback, useEffect } from 'react';

let logoutTimer;

export const useAuth = () => {
  const [token, setToken] = useState(null);
  const [tokenExpiration, setTokenExpiration] = useState();
  const [userData, setUserData] = useState({});
  const [loggedOut, setLoggedOut] = useState(false);

  const login = useCallback((userData, expiration) => {
    setLoggedOut(false);
    setToken(userData.token);
    setUserData({ ...userData });
    const tokenExpDate = expiration || new Date(new Date().getTime() + 1000 * 60 * 60);
    setTokenExpiration(tokenExpDate);
    localStorage.setItem('userData', JSON.stringify({ ...userData, expiration: tokenExpDate.toISOString() }));
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setUserData({});
    setTokenExpiration(null);
    localStorage.removeItem('userData');
  }, []);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('userData'));
    if (storedData && storedData.token && new Date(storedData.expiration) > new Date()) {
      login(storedData, new Date(storedData.expiration));
    }
  }, [login]);

  useEffect(() => {
    if (token) {
      const remainingTime = tokenExpiration.getTime() - new Date().getTime();
      logoutTimer = setTimeout(() => {
        logout();
        setLoggedOut(true);
      }, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpiration]);

  return { token, login, logout, userData, loggedOut };
};
