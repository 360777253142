import React, { useState, useContext } from 'react';
import axios from 'axios';

import ConfirmDelete from './modals/ConfirmDelete';
import Button from '../UI/Button';
import Spinner from '../UI/Spinner';
import { AuthContext } from '../context/auth-context';
import { capitaliseWords } from '../utils/capitaliseWords';

import '../DeleteProduct.css';

const DeleteExtra = ({ id, name, image, price, preview, recentlyAdded, stockCount, onRefreshExtras }) => {
  const auth = useContext(AuthContext);

  const [nameInput, setNameInput] = useState(capitaliseWords(name));
  const [priceInput, setPriceInput] = useState(price);
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [edited, setEdited] = useState(false);
  const [visibleConfirmDelete, setVisibleConfirmDelete] = useState(false);
  const [isRecentlyAdded, setIsRecentlyAdded] = useState(recentlyAdded);
  const [stockAmount, setStockAmount] = useState(stockCount);
  const [stockUpdated, setStockUpdated] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // toggles edit mode
  const editModeHandler = () => {
    setEditMode((prevState) => !prevState);
  };

  const nameInputHandler = (event) => {
    setNameInput(event.target.value);
  };

  const priceInputHandler = (event) => {
    setPriceInput(event.target.value);
  };

  // edits bagg name
  const editExtraHandler = () => {
    const editExtra = async (newName, newPrice) => {
      setIsLoading(true);
      try {
        await axios
          .put(
            `${process.env.REACT_APP_BACKEND_URL}/api/extras/${id}`,
            { name: newName, price: newPrice },
            { headers: { Authorization: 'Bearer ' + auth.userData.token } }
          )
          .then((res) => {
            setEditMode(false);
            onRefreshExtras(res.data);
            setEdited(true);
            setIsLoading(false);
            setTimeout(() => setEdited(false), 2000);
          });
      } catch (err) {
        setError(true);
        setErrorMessage(err.response.data.message);
        setIsLoading(false);
        setTimeout(() => setError(false), 2000);
      }
    };

    const newName = capitaliseWords(nameInput);
    const newPrice = priceInput;
    editExtra(newName, newPrice);
  };

  // show delete confirmation
  const showConfirmDeleteHandler = () => {
    setVisibleConfirmDelete(true);
  };

  // hide delete confirmation
  const hideConfirmDeleteHandler = () => {
    setVisibleConfirmDelete(false);
  };

  // updates recently added sweets on home page
  const recentlyAddedHandler = () => {
    const updateRecentlyAddedHandler = async () => {
      try {
        await axios
          .put(
            `${process.env.REACT_APP_BACKEND_URL}/api/extras/recently-added/${id}`,
            { recentlyAdded: !isRecentlyAdded },
            { headers: { Authorization: 'Bearer ' + auth.userData.token } }
          )
          .then(() => {
            setIsRecentlyAdded((prevState) => !prevState);
          });
      } catch (err) {
        setError(true);
        setErrorMessage(err.response.data.message);
        setTimeout(() => setError(false), 2000);
      }
    };
    updateRecentlyAddedHandler();
  };

  // updates in stock/out of stock on order & sweets pages
  const stockHandler = (event) => {
    setError(false);
    if (event.target.value.includes('.')) {
      setError(true);
      setErrorMessage('Please use a whole number for stock');
    }
    setStockAmount(event.target.value);
  };

  const updateStockHandler = async () => {
    try {
      await axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/api/extras/stock/${id}`,
          { stockCount: stockAmount },
          { headers: { Authorization: 'Bearer ' + auth.userData.token } }
        )
        .then((res) => {
          setStockUpdated(true);
          setTimeout(() => {
            setStockUpdated(false);
          }, 2000);
        });
    } catch (err) {
      setError(true);
      setErrorMessage(err.response.data.message);
      setTimeout(() => setError(false), 2000);
    }
  };

  return (
    <>
      <li className={stockAmount > 0 ? 'delete-product-container' : 'delete-product-container-out-of-stock'}>
        {!isLoading && !stockUpdated && (
          <div className='sweet-info-container'>
            <input type='text' size='4' value={stockAmount} onChange={stockHandler} style={{ fontSize: '1rem' }} />
            <h4 className={stockAmount > 0 ? 'in-stock' : 'not-in-stock'}> in stock</h4>
          </div>
        )}
        {!isLoading && stockUpdated && <h3 style={{ margin: '9px' }}>Stock Updated</h3>}
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <Button onClick={updateStockHandler}>Update</Button>
            <div className='sweet-image-container'>
              <img src={preview} alt={name} className='delete-product__image' />
              <div className='sweet-image-centered-text'>
                <h3>{name}</h3>
              </div>
            </div>
            <div className='sweet-info-container'>
              <h4>{`£${parseFloat(price).toFixed(2)}`}</h4>
            </div>
            <div className='sweet-info-container'>
              <h4>New Product</h4>
              <input type='checkbox' className='checkbox' checked={isRecentlyAdded} onChange={recentlyAddedHandler} />
            </div>
            {!isLoading && editMode && (
              <>
                <input type='text' value={nameInput} onChange={nameInputHandler} />
                <input type='text' value={priceInput} onChange={priceInputHandler} />
              </>
            )}
            {!isLoading && !editMode && (
              <span>
                <Button onClick={editModeHandler}>Edit</Button>
                <button className='remove-button' onClick={showConfirmDeleteHandler}>
                  Delete
                </button>
              </span>
            )}
            {editMode && (
              <span>
                <button className='remove-button' onClick={editModeHandler}>
                  Back
                </button>
                <Button onClick={editExtraHandler}>Confirm</Button>
              </span>
            )}
            {edited && (
              <div className='modal-text' style={{ margin: 0 }}>
                <h3>Updated!</h3>
              </div>
            )}
            {error && (
              <div className='error-modal-text'>
                <h3>{errorMessage}</h3>
              </div>
            )}
          </>
        )}
      </li>
      {visibleConfirmDelete && (
        <ConfirmDelete
          onHideConfirmDelete={hideConfirmDeleteHandler}
          id={id}
          name={name}
          image={image}
          preview={preview}
          productType={'extras'}
          onRefreshProducts={onRefreshExtras}
        />
      )}
    </>
  );
};
export default DeleteExtra;
