import React from 'react';

import EventForm from '../components/EventForm';
import ContactDetails from '../components/ContactDetails';

const Contact = () => {
  return (
    <>
      <h1>Contact</h1>
      <EventForm />
      <ContactDetails />
    </>
  );
};

export default Contact;
