import React, { useContext } from 'react';

import BasketSelectedBagg from './BasketSelectedBagg';
import BasketSelectedExtra from './BasketSelectedExtra';
import { BasketContext } from '../context/basket-context';

const SelectedProduct = ({ onRemoveBagg, onRemoveExtra, onStartAgain }) => {
  const { orderState, dispatchOrder } = useContext(BasketContext);

  let uniqueExtras = [...new Map(orderState.basket.extras.map((item) => [item['name'], item])).values()];

  const counts = {};
  const extraCounter = () => {
    orderState.basket.extras.forEach((x) => {
      counts[x.name] = (counts[x.name] || 0) + 1;
    });
  };
  extraCounter();

  // adds and removes baggs to basket
  const decreaseBaggHandler = (id, quantity) => {
    dispatchOrder({ type: 'DECREASE_BASKET_BAGG_QTY', payload: { id, quantity } });
  };

  const increaseBaggHandler = (id, quantity) => {
    dispatchOrder({ type: 'INCREASE_BASKET_BAGG_QTY', payload: { id, quantity } });
  };

  // adds and removes extras to basket
  const addExtraHandler = (quantity, stock, name, image, price) => {
    dispatchOrder({ type: 'ADD_EXTRA', payload: { quantity, stock, name, image, price } });
  };

  const removeExtraHandler = (extra) => {
    dispatchOrder({ type: 'REMOVE_SINGLE_EXTRA_FROM_BASKET', payload: extra });
  };

  return (
    <>
      <div>
        <button className='remove-button' onClick={onStartAgain}>
          Start again
        </button>
      </div>
      <div className='basket-container'>
        <ul style={{ margin: 0 }}>
          {orderState.basket.baggs.length > 0 &&
            orderState.basket.baggs.map((o) => (
              <BasketSelectedBagg
                key={o.id}
                id={o.id}
                name={o.bagg}
                image={o.image}
                size={o.size}
                sweets={o.sweets}
                price={o.price}
                quantity={o.quantity}
                onRemoveBagg={onRemoveBagg}
                onIncreaseBagg={increaseBaggHandler}
                onDecreaseBagg={decreaseBaggHandler}
              />
            ))}
        </ul>
        <ul style={{ margin: 0 }}>
          {uniqueExtras.length > 0 &&
            uniqueExtras.map((o) => (
              <BasketSelectedExtra
                key={o.id}
                id={o.id}
                stock={o.stock}
                name={o.name}
                quantity={counts[o.name]}
                addExtra={addExtraHandler}
                decreaseExtra={removeExtraHandler}
                image={o.image}
                price={o.price}
                onRemoveExtra={onRemoveExtra}
              />
            ))}
        </ul>
      </div>
    </>
  );
};

export default SelectedProduct;
