import React from 'react';

import Button from '../UI/Button';
import { convertToKilos } from '../utils/convertToKilos';

const SizeItem = (props) => {
  // returns size
  const selectSizeHandler = () => {
    props.onSelectSize(props.size, props.price, props.amount);
  };

  return (
    <li>
      <Button onClick={selectSizeHandler}>
        <h3>{`${convertToKilos(props.size)} Bag`}</h3>
        <p>{`£${parseFloat(props.price).toFixed(2)}`}</p>
      </Button>
    </li>
  );
};

export default SizeItem;
