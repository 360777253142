import React, { useState, useRef, useContext } from 'react';
import axios from 'axios';

import Modal from '../../UI/Modal';
import Button from '../../UI/Button';
import Spinner from '../../UI/Spinner';
import { AuthContext } from '../../context/auth-context';
import { convertToKilos } from '../../utils/convertToKilos';

const AddSize = (props) => {
  const auth = useContext(AuthContext);

  const sizeInputRef = useRef();
  const amountInputRef = useRef();
  const priceInputRef = useRef();
  const [alreadyExists, setAlreadyExists] = useState(false);
  const [emptySize, setEmptySize] = useState(false);
  const [emptyAmount, setEmptyAmount] = useState(false);
  const [emptyPrice, setEmptyPrice] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [added, setAdded] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // size submit function
  const postSize = async (size, price, amount) => {
    setIsLoading(true);
    try {
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/api/sizes`, { size, price, amount }, { headers: { Authorization: 'Bearer ' + auth.userData.token } })
        .then((res) => {
          props.onSizePost(res.data);
          setAdded(true);
          setIsLoading(false);
          setTimeout(() => props.onClose(), 2000);
        });
    } catch (err) {
      setError(true);
      setErrorMessage(err.response.data.message);
      setIsLoading(false);
      setTimeout(() => setError(false), 2000);
    }
  };

  // submits new size after validation
  const submitNewSizeHandler = () => {
    const newSize = parseFloat(sizeInputRef.current.value);
    const newAmount = parseFloat(amountInputRef.current.value);
    const newPrice = parseFloat(priceInputRef.current.value);
    if (!newSize) {
      setEmptySize(true);
      return;
    }
    if (!newAmount) {
      setEmptyAmount(true);
      return;
    }
    if (!newPrice) {
      setEmptyPrice(true);
      return;
    }
    if (props.sizes.some((s) => s.size === newSize)) {
      setAlreadyExists(true);
      return;
    }
    postSize(newSize, newPrice, newAmount);
  };

  const resetHandler = () => {
    setAlreadyExists(false);
    setEmptySize(false);
    setEmptyAmount(false);
    setEmptyPrice(false);
  };

  return (
    <Modal onClose={props.onClose}>
      <div className='modal-title'>
        <h2>Add size</h2>
      </div>
      <div className='modal-text'>
        <input type='text' ref={sizeInputRef} size='8' maxLength='5' onFocus={resetHandler} placeholder='New Bag Size (g)' />
        <input type='text' ref={amountInputRef} size='8' maxLength='5' onFocus={resetHandler} placeholder='Maximum Sweet Limit' />
        <input type='text' ref={priceInputRef} size='8' maxLength='6' onFocus={resetHandler} placeholder='Price (£)' />
      </div>
      {added && (
        <div className='modal-text'>
          <h3>'{convertToKilos(sizeInputRef.current.value)} bag' added successfully!</h3>
        </div>
      )}
      {emptySize && (
        <div className='error-modal-text'>
          <h3>Please enter a valid size in grams!</h3>
        </div>
      )}
      {emptyAmount && (
        <div className='error-modal-text'>
          <h3>Please enter a valid sweet limit for this size!</h3>
        </div>
      )}
      {emptyPrice && (
        <div className='error-modal-text'>
          <h3>Please enter a valid price for this bag!</h3>
        </div>
      )}
      {alreadyExists && (
        <div className='error-modal-text'>
          <h3>Size already exists!</h3>
        </div>
      )}
      {error && (
        <div className='error-modal-text'>
          <h3>{errorMessage}</h3>
        </div>
      )}
      <div className='modal-actions'>
        {!added && !isLoading && (
          <Button className='remove-button' onClick={props.onClose}>
            Close
          </Button>
        )}
        {isLoading ? <Spinner /> : !added && <Button onClick={submitNewSizeHandler}>Add Size</Button>}
      </div>
    </Modal>
  );
};

export default AddSize;
