import React, { useState } from 'react';
import { FaMinusCircle } from 'react-icons/fa';
import { FaPlusCircle } from 'react-icons/fa';

const BasketSelectedExtra = (props) => {
  const [removed, setRemoved] = useState(false);

  const addExtraHandler = () => {
    if (props.quantity < props.stock) {
      props.addExtra(1, props.stock, props.name, props.image, props.price);
    }
  };

  const decreaseExtraHandler = () => {
    if (props.quantity > 1) {
      props.decreaseExtra(props.id);
    }
  };

  const removeExtraHandler = () => {
    setRemoved(true);
    setTimeout(() => {
      props.onRemoveExtra(props.name);
    }, 900);
  };

  return (
    <li>
      <div className={!removed ? 'basket-item' : 'basket-item-removed'}>
        <div className='basket-image-container'>
          <img src={props.image} alt={props.name} className='image' />
        </div>
        <div className='basket-product-container'>
          <h3>{props.name}</h3>
          <div className='product-controls'>
            <FaMinusCircle className='controlsIcon' onClick={decreaseExtraHandler} />
            <p style={{ margin: '0px 5px' }}>{props.quantity}</p>
            <FaPlusCircle className='controlsIcon' onClick={addExtraHandler} />
          </div>
          <h3 className='product-info'>£{parseFloat(props.quantity * props.price).toFixed(2)}</h3>
        </div>
        <div>
          <button className='delete-button' onClick={removeExtraHandler}>
            X
          </button>
        </div>
      </div>
      <hr />
    </li>
  );
};

export default BasketSelectedExtra;
