import React, { useState } from 'react';
import { FaMinusCircle } from 'react-icons/fa';
import { FaPlusCircle } from 'react-icons/fa';

import { convertToKilos } from '../utils/convertToKilos';

const BasketSelectedBagg = (props) => {
  const [removed, setRemoved] = useState(false);

  const increaseBaggHandler = () => {
    props.onIncreaseBagg(props.id, props.quantity);
  };
  const decreaseBaggHandler = () => {
    props.onDecreaseBagg(props.id, props.quantity);
  };

  const removeBaggHandler = () => {
    setRemoved(true);
    setTimeout(() => {
      props.onRemoveBagg(props.id);
    }, 900);
  };

  return (
    <li>
      <div className={!removed ? 'basket-item' : 'basket-item-removed'}>
        <div className='basket-image-container'>
          <img src={props.image} alt={props.name} className='image' />
        </div>
        <div className='basket-product-container'>
          <h3>
            {convertToKilos(props.size)} {props.name}
          </h3>
          {props.name === 'Build Your Own' && props.sweets.length > 0 && (
            <>
              <p>
                <b>With:</b>
              </p>
              <ul>
                {props.sweets.map((s) => (
                  <p key={s.sweet}>{s.sweet}</p>
                ))}
              </ul>
            </>
          )}
          <div className='product-controls'>
            <FaMinusCircle className='controlsIcon' onClick={decreaseBaggHandler} />
            <p style={{ margin: '0px 5px' }}>{props.quantity}</p>
            <FaPlusCircle className='controlsIcon' onClick={increaseBaggHandler} />
          </div>
          <h3 className='product-info'>{props.price && `£${parseFloat(props.quantity * props.price).toFixed(2)}`}</h3>
        </div>
        <div>
          <button className='delete-button' onClick={removeBaggHandler}>
            X
          </button>
        </div>
      </div>
      <hr />
    </li>
  );
};

export default BasketSelectedBagg;
