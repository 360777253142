import React from 'react';

import ExtraItem from './ExtraItem';

const Extras = (props) => {
  return (
    <div>
      <h2>Other Products</h2>
      <ul className='products-container'>
        {props.extras.map((extra) => (
          <ExtraItem
            key={extra._id}
            name={extra.name}
            price={extra.price}
            types={extra.types}
            stockCount={extra.stockCount}
            image={extra.imageURL}
            preview={extra.previewImageURL}
            onSelectExtra={props.onSelectExtra}
          />
        ))}
      </ul>
    </div>
  );
};

export default Extras;
