import React from 'react';

import Modal from '../../UI/Modal';
import Button from '../../UI/Button';
import { convertToKilos } from '../../utils/convertToKilos';

const SweetLimit = (props) => {
  return (
    <Modal onClose={props.onHideSweetLimit}>
      <div className='modal-title'>
        <h2>Maximum amount of sweets reached for a {convertToKilos(props.selectedSize)} bag!</h2>
      </div>
      <div className='modal-text'>
        <p>Please remove some sweets or upgrade to a larger bag.</p>
      </div>
      <div className='modal-actions'>
        <Button onClick={props.onHideSweetLimit}>Okay</Button>
      </div>
    </Modal>
  );
};

export default SweetLimit;
