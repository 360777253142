import React, { useState, useEffect } from 'react';
import RecentlyAddedSweet from '../components/RecentlyAddedSweet';
import RecentlyAddedBagg from '../components/RecentlyAddedBagg';
import axios from 'axios';

import image from '../images/bagg.jpg';
import Spinner from '../UI/Spinner';

const Home = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [baggs, setBaggs] = useState([]);
  const [sweets, setSweets] = useState([]);
  const [sweetCount, setSweetCount] = useState(0);
  const recentlyAddedBaggs = baggs.filter((item) => item.recentlyAdded === true);
  const recentlyAddedSweets = sweets.filter((item) => item.recentlyAdded === true);

  // retrieves sweets
  useEffect(() => {
    setIsLoading(true);
    const fetchProducts = async () => {
      const { data: baggData } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/baggs`);
      const { data: sweetData } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/products`);
      const { data: extraData } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/extras`);
      setBaggs([...baggData]);
      setSweets([...sweetData.sort((a, b) => a.category.localeCompare(b.category)), ...extraData]);
      setSweetCount(sweetData.length);
      setIsLoading(false);
    };
    fetchProducts();
  }, []);

  return (
    <>
      <h1>Home</h1>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className='intro'>
          <div className='intro-half'>
            <img src={image} alt='bag' className='home-image' />
          </div>
          <div className='intro-half'>
            <h3>Welcome to Baggs of Sweets!</h3>
            <p>
              With a range of over {Math.floor(sweetCount / 10) * 10} different types of pick and mix sweets for you to choose from, there
              is something for everyone!
            </p>
            <p>
              Perfect for a baby shower, wedding favour, gift for a loved one or just a treat for yourself.. every occasion can be catered
              for.
            </p>
            <p>
              Local delivery is available, or postage starts from £3.
              <br />
              &#40;depending on the size of the order&#41;
            </p>
            <p>
              If you are looking for sweets for an event, please contact us by clicking <a href='/contact'>here</a>.
            </p>
          </div>
        </div>
      )}
      {recentlyAddedBaggs.length > 0 || recentlyAddedSweets.length > 0 ? (
        <div>
          <h2>New Products</h2>
          <ul className='recently-added-container'>
            {recentlyAddedBaggs.map((bagg) => (
              <RecentlyAddedBagg key={bagg.name} name={bagg.name} image={bagg.imageURL} preview={bagg.previewImageURL} />
            ))}
            {recentlyAddedSweets.map((sweet) => (
              <RecentlyAddedSweet key={sweet.name} name={sweet.name} image={sweet.imageURL} preview={sweet.previewImageURL} />
            ))}
          </ul>
        </div>
      ) : null}
    </>
  );
};

export default Home;
