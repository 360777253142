import React from 'react';

const SweetCheckbox = (props) => {
  const checkHandler = () => {
    props.checkFilterHandler(props.filter);
  };

  return (
    <div className='checkbox-check'>
      <input type='checkbox' className='checkbox' onChange={checkHandler} defaultChecked />
    </div>
  );
};

export default SweetCheckbox;
