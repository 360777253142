export const modalReducer = (state, action) => {
  switch (action.type) {
    case 'SHOW_ADD_CATEGORY':
      return { ...state, showAddCategory: true };
    case 'SHOW_REMOVE_CATEGORY':
      return { ...state, showRemoveCategory: true };
    case 'SHOW_ADD_BAGG':
      return { ...state, showAddBagg: true };
    case 'SHOW_REMOVE_BAGG':
      return { ...state, showRemoveBagg: true };
    case 'SHOW_ADD_SIZE':
      return { ...state, showAddSize: true };
    case 'SHOW_REMOVE_SIZE':
      return { ...state, showRemoveSize: true };
    case 'HIDE_MODAL':
      return {};
    default:
      return state;
  }
};
