import React, { useState, useRef, useContext } from 'react';
import axios from 'axios';

import Modal from '../../UI/Modal';
import Button from '../../UI/Button';
import Spinner from '../../UI/Spinner';
import ImageUpload from '../../UI/ImageUpload';
import { AuthContext } from '../../context/auth-context';
import { capitaliseWords } from '../../utils/capitaliseWords';
import { capitalise } from '../../utils/capitaliseWord';

const AddBagg = (props) => {
  const auth = useContext(AuthContext);

  const baggInputRef = useRef();
  const descriptionInputRef = useRef();
  const [image, setImage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [added, setAdded] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // selects image
  const selectImageHandler = (selectedImage) => {
    setImage(selectedImage);
  };

  // bagg submit function
  const postBagg = async (name, description, image) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('description', description);
      formData.append('image', image);
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/baggs`, formData, { headers: { Authorization: 'Bearer ' + auth.userData.token } }).then((res) => {
        props.onBaggPost(res.data);
        setIsLoading(false);
        setAdded(true);
        setTimeout(() => props.onClose(), 2000);
      });
    } catch (err) {
      setError(true);
      setErrorMessage(err.response.data.message);
      setIsLoading(false);
      setTimeout(() => setError(false), 2000);
    }
  };

  // submits new bagg after validation
  const submitNewBaggHandler = () => {
    if (!baggInputRef.current.value) {
      setError(true);
      setErrorMessage('Please enter a bag name!');
      return;
    }
    if (!descriptionInputRef.current.value) {
      setError(true);
      setErrorMessage('Please enter a bag description!');
      return;
    }
    if (!image) {
      setError(true);
      setErrorMessage('Please select an image for your bag!');
      return;
    }
    const bagg = capitaliseWords(baggInputRef.current.value);
    const description = capitalise(descriptionInputRef.current.value);
    if (props.baggs.some((e) => e.name === bagg)) {
      setError(true);
      setErrorMessage('Bag already exists!');
      return;
    }
    postBagg(bagg, description, image);
  };

  // resets errors
  const resetHandler = () => {
    setError(false);
  };

  return (
    <Modal onClose={props.onClose}>
      <div className='modal-title'>
        <h2>Add bag</h2>
      </div>
      <div className='modal-text'>
        <input id='bagg' type='text' ref={baggInputRef} onFocus={resetHandler} placeholder='Bag Name' />
        <textarea id='description' rows='2' maxLength='42' ref={descriptionInputRef} onFocus={resetHandler} placeholder='Bag Description' />
      </div>
      <div className='modal-text'>
        <ImageUpload id='image' onSelectImage={selectImageHandler} />
      </div>
      {added && (
        <div className='modal-text'>
          <h3>'{capitaliseWords(baggInputRef.current.value)} bag' added successfully!</h3>
        </div>
      )}
      {error && (
        <div className='error-modal-text'>
          <h3>{errorMessage}</h3>
        </div>
      )}
      <div className='modal-actions'>
        {!added && !isLoading && (
          <Button className='remove-button' onClick={props.onClose}>
            Close
          </Button>
        )}
        {isLoading ? <Spinner /> : !added && <Button onClick={submitNewBaggHandler}>Add Bag</Button>}
      </div>
    </Modal>
  );
};

export default AddBagg;
