import React, { useState, useContext } from 'react';
import axios from 'axios';

import Modal from '../../UI/Modal';
import Button from '../../UI/Button';
import Spinner from '../../UI/Spinner';
import { AuthContext } from '../../context/auth-context';
import { dateFormatter } from '../../utils/dateFormatter';

const ConfirmEventComplete = (props) => {
  const auth = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [completed, setCompleted] = useState(false);

  // completes request
  const completeEventReq = async (event) => {
    setIsLoading(true);
    try {
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/api/event-enquiry/complete`, event, { headers: { Authorization: 'Bearer ' + auth.userData.token } })
        .then((res) => {
          setTimeout(() => {
            props.onUpdate(res.data);
            props.onHide();
          }, 2000);
          setIsLoading(false);
          setCompleted(true);
        });
    } catch (err) {
      setError(true);
      setErrorMessage(err.response.data.message);
      setIsLoading(false);
      setTimeout(() => setError(false), 2000);
    }
  };

  return (
    <Modal onClose={props.onHide}>
      <div className='modal-title'>
        <h2>Are you sure you want to complete?</h2>
      </div>
      <div className='modal-column'>
        <div>
          <h3>{props.request.name}</h3>
          <p>{props.request.email}</p>
          <hr />
        </div>
        <div>
          <h3>{props.request.occasion}</h3>
          <p>on {dateFormatter(props.request.date)}</p>
        </div>
      </div>
      {completed && (
        <div className='modal-text'>
          <h3>Request Completed!</h3>
        </div>
      )}
      {error && (
        <div className='error-modal-text'>
          <h3>{errorMessage}</h3>
        </div>
      )}
      <div className='modal-actions'>
        <Button className='remove-button' onClick={props.onHide}>
          Close
        </Button>
        {isLoading ? <Spinner /> : !completed && <Button onClick={() => completeEventReq(props.request)}>Complete Request</Button>}
      </div>
    </Modal>
  );
};

export default ConfirmEventComplete;
