import React, { useState, useRef } from 'react';
import axios from 'axios';

import Button from '../../../UI/Button';
import ConfirmOrderItem from './ConfirmOrderItem';
import ConfirmOrderExtraItem from './ConfirmOrderExtraItem';

const ConfirmOrderForm = ({ onHideConfirmOrder, basket, extras, counts, onRemoveBagg, onRemoveExtra, totalPrice, onStartAgain }) => {
  const nameInputRef = useRef();
  const emailInputRef = useRef();
  const addressInputRef = useRef();
  const postcodeInputRef = useRef();
  const messageInputRef = useRef();
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // resets input error
  const resetInputHandler = () => {
    setError(false);
  };

  // sends enquiry after confirmation
  const enquiryHandler = () => {
    const name = nameInputRef.current.value;
    const email = emailInputRef.current.value;
    const address = addressInputRef.current.value;
    const postcode = postcodeInputRef.current.value;
    const message = messageInputRef.current.value;
    if (basket.baggs.length + extras.length === 0) {
      setError(true);
      setErrorMessage('Your sweet enquiry is empty.');
      return;
    }
    const sendEnquiry = async () => {
      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/product-enquiry`, {
          order: basket,
          name,
          email,
          address,
          postcode,
          message,
          totalPrice,
        });
        setSubmitted(true);
        setError(false);
        onStartAgain();
      } catch (err) {
        setSubmitted(false);
        setError(true);
        setErrorMessage(err.response.data.message);
      }
    };
    sendEnquiry();
  };

  return (
    <>
      {!submitted && (
        <div className='enquiry-form'>
          {basket.baggs.length > 0 &&
            basket.baggs.map((o) => <ConfirmOrderItem key={o.id} id={o.id} bagg={o.bagg} size={o.size} quantity={o.quantity} sweets={o.sweets} onRemoveBagg={onRemoveBagg} />)}
          {basket.extras.length > 0 &&
            extras.map((o) => <ConfirmOrderExtraItem key={o.id} id={o.id} name={o.name} quantity={counts[o.name]} onRemoveExtra={onRemoveExtra} />)}
          <h2 className='product-info'>£{parseFloat(totalPrice).toFixed(2)}</h2>
          <input id='name' type='text' placeholder='Name*' required='required' ref={nameInputRef} onFocus={resetInputHandler} />
          <input id='email' type='email' placeholder='Email address*' required='required' ref={emailInputRef} onFocus={resetInputHandler} />
          <textarea
            id='address'
            rows='3'
            maxLength='255'
            placeholder='Delivery address*'
            required='required'
            ref={addressInputRef}
            onFocus={resetInputHandler}
          />
          <input id='postcode' type='text' placeholder='Postcode*' required='required' ref={postcodeInputRef} />
          <textarea
            id='message'
            rows='5'
            maxLength='255'
            placeholder='Please state any preferences eg. more of/less of any particular sweets'
            ref={messageInputRef}
          />
          <p>You will be contacted for payment after you have ordered.</p>
          <b style={{ color: '#F71B0F', margin: 0 }}>* Required Fields</b>
        </div>
      )}
      {submitted && (
        <div className='modal-column'>
          <h3>Order sent successfully</h3>
          <p>You will be contacted for payment, thank you.</p>
        </div>
      )}
      {error && (
        <div className='error-modal-text'>
          <h3>{errorMessage}</h3>
        </div>
      )}
      <div className='modal-actions'>
        {!submitted ? (
          <>
            <Button className='remove-button' onClick={onHideConfirmOrder}>
              Cancel
            </Button>
            <Button onClick={enquiryHandler}>Order now</Button>
          </>
        ) : (
          <Button onClick={onHideConfirmOrder}>Close</Button>
        )}
      </div>
    </>
  );
};

export default ConfirmOrderForm;
