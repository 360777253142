import React, { useState } from 'react';

import Modal from '../../UI/Modal';
import Button from '../../UI/Button';
import RemoveSizeItem from './modalItems/RemoveSizeItem';
import EditSize from './EditSize';

const RemoveSize = (props) => {
  const [editMode, setEditMode] = useState(false);
  const [sizeToBeEdited, setSizeToBeEdited] = useState({});
  const [removed, setRemoved] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // sorts sizes
  const sizes = props.sizes.sort((a, b) => a.size - b.size);

  // selects a size to be edited
  const sizeEdit = (id, size, amount, price) => {
    setEditMode(true);
    setSizeToBeEdited({ id, size, amount, price });
  };

  const closeEditHandler = () => {
    setEditMode(false);
  };

  return (
    <Modal onClose={props.onClose}>
      <div className='modal-title'>
        <h2>{!editMode ? 'Remove size' : 'Edit size'}</h2>
      </div>
      <div className='modal-body'>
        {!editMode && (
          <ul className='modal-text'>
            {sizes.length > 0 ? (
              sizes.map((s) => (
                <RemoveSizeItem
                  key={s._id}
                  id={s._id}
                  size={s.size}
                  amount={s.amount}
                  price={s.price}
                  onSizeDelete={props.onSizeDelete}
                  onSizeEdit={sizeEdit}
                  onRemove={setRemoved}
                  onError={setError}
                  onErrorMessage={setErrorMessage}
                />
              ))
            ) : (
              <p>You have no sizes.</p>
            )}
          </ul>
        )}
        {editMode && <EditSize size={sizeToBeEdited} onBack={closeEditHandler} onSizeEdit={props.onSizeDelete} />}
        {removed && (
          <div className='modal-text'>
            <h3>Size removed!</h3>
          </div>
        )}
        {error && (
          <div className='error-modal-text'>
            <h3>{errorMessage}</h3>
          </div>
        )}
        <div className='modal-actions'>
          <Button className='remove-button' onClick={props.onClose}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveSize;
