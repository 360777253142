import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { BsFillBasket2Fill } from 'react-icons/bs';

import NavBar from './NavBar';
import { BasketContext } from '../context/basket-context';
import logo from '../images/logo3.jpg';

const Header = () => {
  const { orderState } = useContext(BasketContext);

  const [totalBaggQuantity, setTotalBaggQuantity] = useState(0);

  useEffect(() => {
    let baggCount = 0;

    orderState.basket.baggs.map((bagg) => {
      return (baggCount += bagg.quantity);
    });
    setTotalBaggQuantity(baggCount);
  }, [orderState.basket.baggs]);

  return (
    <header className='header'>
      <Link to='/basket' className='basket-link'>
        <div className='basket-number'>{totalBaggQuantity + orderState.basket.extras.length}</div>
        <BsFillBasket2Fill className='socialIcon' />
      </Link>
      <img src={logo} alt='Baggs of Sweets' className='header-logo' />
      <NavBar />
    </header>
  );
};

export default Header;
