import React from 'react';

import spinner from '../images/spinner.png';

const Spinner = () => {
  return (
    <>
      <img src={spinner} alt='' className='spinner-container' />
    </>
    // <div className='spinner'>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    // </div>
  );
};

export default Spinner;
