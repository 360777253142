import React, { useState } from 'react';

import FullSizeSweet from './modals/FullSizeSweet';

const EachSweet = (props) => {
  const [visibleFullSize, setVisibleFullSize] = useState(false);

  const displayFullSize = () => {
    setVisibleFullSize(true);
  };

  const hideFullSize = () => {
    setVisibleFullSize(false);
  };

  return (
    <>
      <li className='sweet-container' onClick={displayFullSize}>
        <div className='product-image'>
          <div className='sweet-image-container'>
            <img src={`${props.preview}`} alt={props.name} className={props.inStock ? 'sweet-image' : 'out-of-stock'} />
            {!props.inStock ? (
              <div className='img-centered-text'>
                <b>Out of Stock</b>
              </div>
            ) : null}
            {props.types.some((t) => t.includes('glutenFree')) && <div className='img-gluten-text'>GF</div>}
            {!props.types.some((t) => t.includes('vegan')) && props.types.some((t) => t.includes('vegetarian')) && (
              <div className='img-veggie-text'>V</div>
            )}
            {props.types.some((t) => t.includes('vegan')) && <div className='img-vegan-text'>Vg</div>}
          </div>
        </div>
        <div className='product-name'>
          <p>{props.name}</p>
        </div>
      </li>
      {visibleFullSize && <FullSizeSweet onClose={hideFullSize} name={props.name} image={props.image} />}
    </>
  );
};

export default EachSweet;
