import React from 'react';

import Modal from '../../UI/Modal';
import ConfirmOrderForm from './modalItems/ConfirmOrderForm';

const ConfirmOrder = ({ onHideConfirmOrder, basket, extras, counts, onRemoveBagg, onRemoveExtra, totalPrice, onStartAgain }) => {
  return (
    <Modal onClose={onHideConfirmOrder}>
      <div className='modal-title'>
        <h2>Confirm your order</h2>
      </div>
      <ConfirmOrderForm
        basket={basket}
        extras={extras}
        counts={counts}
        onRemoveBagg={onRemoveBagg}
        onRemoveExtra={onRemoveExtra}
        totalPrice={totalPrice}
        onStartAgain={onStartAgain}
        onHideConfirmOrder={onHideConfirmOrder}
      />
    </Modal>
  );
};

export default ConfirmOrder;
