import React from 'react';

import DeleteExtra from './DeleteExtra';
import Card from '../UI/Card';

const RemoveExtras = (props) => {
  return (
    <Card className='admin-widgets'>
      <div className='admin-container'>
        <h2>Remove Other Products</h2>
        <ul>
          <div className='delete-products-container'>
            {props.products.length === 0 ? (
              <p>You have no other products</p>
            ) : (
              props.products.map((product) => (
                <DeleteExtra
                  key={product._id}
                  id={product._id}
                  name={product.name}
                  price={product.price}
                  image={product.imageURL}
                  preview={product.previewImageURL}
                  recentlyAdded={product.recentlyAdded}
                  stockCount={product.stockCount}
                  onRefreshExtras={props.onRefreshExtras}
                />
              ))
            )}
          </div>
        </ul>
      </div>
    </Card>
  );
};

export default RemoveExtras;
