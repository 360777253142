import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { AuthContext } from '../context/auth-context';

import { dateFormatter } from '../utils/dateFormatter';
import { timeFormatter } from '../utils/timeFormatter';
import { convertToKilos } from '../utils/convertToKilos';

const CompletedOrderRequests = () => {
  const auth = useContext(AuthContext);

  const [completedProductRequests, setCompletedProductRequests] = useState([]);

  // filters unique extras from order
  let uniqueExtras = (array) => {
    return [...new Map(array.map((item) => [item['name'], item])).values()];
  };

  // counts how many of each extra there is
  const extraCounter = (array, item) => {
    let counter = 0;
    for (let i = 0; i < array.length; i++) {
      if (array[i].name === item) counter++;
    }
    return counter;
  };

  useEffect(() => {
    const retrieveCompletedProducts = async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/product-enquiry/complete`, {
        headers: { Authorization: 'Bearer ' + auth.userData.token },
      });
      setCompletedProductRequests(data.sort((a, b) => new Date(b.completed).getTime() - new Date(a.completed).getTime()));
    };
    retrieveCompletedProducts();
  }, [auth.userData.token]);

  return (
    <>
      {completedProductRequests.length === 0 && <p>You have no completed orders!</p>}
      {completedProductRequests.length > 0 && (
        <>
          <h3>Completed Order Requests</h3>
          <div className='table-container'>
            <table>
              <thead>
                <tr>
                  <th>Submitted</th>
                  <th>Name, Email &amp; Address</th>
                  <th>Bag</th>
                  <th>Sweets</th>
                  <th>Other Products</th>
                  <th>Additional Info</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {completedProductRequests.map((request) => (
                  <tr key={request._id}>
                    <td>
                      {dateFormatter(request.submittedOn)}
                      <br />
                      {timeFormatter(request.submittedOn)}
                    </td>
                    <td>
                      <b>{request.name}</b>
                      <br />
                      {request.email}
                      <br />
                      <br />
                      {request.address}
                      <br />
                      {request.postcode}
                    </td>
                    <td>
                      {request.order
                        .map((b) =>
                          b.baggs.length > 0
                            ? b.baggs
                                .map((o) => (
                                  <React.Fragment key={o.id}>
                                    {convertToKilos(o.size)}
                                    <br />
                                    {o.bagg}
                                    <br />
                                    <b>Qty: {o.quantity || '1'}</b>
                                  </React.Fragment>
                                ))
                                .reduce((prev, curr) => [prev, <hr key={uuidv4()} />, curr])
                            : 'N/A'
                        )
                        .reduce((prev, curr) => [prev, <hr key={uuidv4()} />, curr])}
                    </td>
                    <td>
                      {request.order
                        .map((b) =>
                          b.baggs.length > 0
                            ? b.baggs
                                .map((o) =>
                                  o.sweets.length > 0 ? (
                                    <React.Fragment key={o.id}>
                                      {o.sweets.map((s) => <React.Fragment key={s.sweet}>{s.sweet}</React.Fragment>).reduce((prev, curr) => [prev, ', ', curr])}
                                      <br />
                                      <b>{` (${o.sweets.length} x ${convertToKilos((o.size / o.sweets.length).toFixed(0))})`}</b>
                                    </React.Fragment>
                                  ) : (
                                    'N/A'
                                  )
                                )
                                .reduce((prev, curr) => [prev, <hr key={uuidv4()} />, curr])
                            : 'N/A'
                        )
                        .reduce((prev, curr) => [prev, <hr key={uuidv4()} />, curr])}
                    </td>
                    <td>
                      {request.order.map((o) =>
                        o.extras.length > 0
                          ? uniqueExtras(o.extras)
                              .map((e) => `${extraCounter(o.extras, e.name)} x ${e.name}`)
                              .reduce((prev, curr) => [prev, <hr key={uuidv4()} />, curr])
                          : 'N/A'
                      )}
                    </td>
                    <td>{request.message ? request.message : 'N/A'}</td>
                    <td>
                      <h3 className='product-info' style={{ padding: '5px' }}>
                        £{parseFloat(request.totalPrice).toFixed(2)}
                      </h3>
                      <p>
                        <b>Responded</b>
                        <br />
                        {dateFormatter(request.respondedDate)}
                        <br />
                        {timeFormatter(request.respondedDate)}
                      </p>
                      <hr />
                      <p>
                        <b>Completed</b>
                        <br />
                        {dateFormatter(request.completed)}
                        <br />
                        {timeFormatter(request.completed)}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default CompletedOrderRequests;
