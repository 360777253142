import React from 'react';

const SelectedSweets = ({ name, image, onRemoveSweet }) => {
  const removeSweetHandler = () => {
    onRemoveSweet(name);
  };

  return (
    <li className='selected-container'>
      <img src={image} alt={name} className='image' />
      <div className='selected-container-text'>
        <p>{name}</p>
      </div>
      <button className='remove-button' onClick={removeSweetHandler}>
        Remove
      </button>
    </li>
  );
};

export default SelectedSweets;
