import React, { useState, useEffect } from 'react';

import Sweet from './Sweet';
import SweetTypeCheckbox from './SweetTypeCheckbox';
import { removeFromArray } from '../utils/removeFromArray';

const Sweets = (props) => {
  const [sweetTypes, setSweetTypes] = useState([]);
  const [filter, setFilter] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);

  // maps existing products for each unique category
  let filters = props.products.map((product) => product.category);
  const uniqueFilters = [...new Set(filters)].sort();

  // updates checkbox type filters and filter array
  const checkTypeHandler = (type) => {
    if (sweetTypes.includes(type)) {
      let removedFilter = [...new Set(removeFromArray(sweetTypes, type))];
      setSweetTypes(() => removedFilter);
      return;
    }
    if (!sweetTypes.includes(type)) {
      setSweetTypes((sweetTypes) => {
        return [...sweetTypes, type];
      });
    }
  };

  // returns a filtered array of sweets by sweet category
  const filterCategoryHandler = (event) => {
    setFilter(event.target.value);
  };

  // displays filtered sweets by category
  useEffect(() => {
    if (filter === '' && sweetTypes.length > 0) {
      let filteredByType = [];
      for (let i = 0; i < sweetTypes.length; i++) {
        filteredByType.push(...props.products.filter((p) => p.types.some((t) => t.includes(sweetTypes[i]))));
      }
      setFilteredProducts([...new Set(filteredByType)]);
    }
    if (filter !== '' && sweetTypes.length > 0) {
      let filteredByType = [];
      for (let i = 0; i < sweetTypes.length; i++) {
        filteredByType = props.products.filter((p) => p.types.some((t) => t.includes(sweetTypes[i])));
      }
      setFilteredProducts(filteredByType.filter((p) => p.category.includes(filter)));
    }
    if (filter === '' && sweetTypes.length === 0) {
      setFilteredProducts(props.products);
    }
    if (filter !== '' && sweetTypes.length === 0) {
      setFilteredProducts(
        props.products.filter((sweet) => {
          return sweet.category.includes(filter);
        })
      );
    }
  }, [sweetTypes, filter, props.products]);

  return (
    <>
      <div>
        <p>Filter sweets by category:</p>
        <select name='category' onChange={filterCategoryHandler} style={{ fontWeight: 'bold' }}>
          <option value=''>Show All</option>
          {uniqueFilters.map((c) => (
            <option key={c} value={c} style={{ padding: '0px 5px' }}>
              {c}
            </option>
          ))}
        </select>
      </div>
      <ul className='products-container'>
        <li className='gluten-checkbox-container'>
          <SweetTypeCheckbox filter='glutenFree' checkbox={'gf-checkbox'} onCheckType={checkTypeHandler} />
          <div className='checkbox-text'>Gluten Free (GF)</div>
        </li>
        <li className='v-checkbox-container'>
          <SweetTypeCheckbox filter='vegetarian' checkbox={'v-checkbox'} onCheckType={checkTypeHandler} />
          <div className='checkbox-text'>Vegetarian (V)</div>
        </li>
        <li className='v-checkbox-container'>
          <SweetTypeCheckbox filter='vegan' checkbox={'v-checkbox'} onCheckType={checkTypeHandler} />
          <div className='checkbox-text'>Vegan (Vg)</div>
        </li>
      </ul>
      <p>
        Your search has returned <big>{filteredProducts.length}</big> sweets{filteredProducts.length === 0 && ', please try again'}.
      </p>
      <ul className='products-container'>
        {filteredProducts.length > 0 &&
          filteredProducts.map((product) => (
            <Sweet
              key={product.name}
              name={product.name}
              image={product.previewImageURL}
              inStock={product.inStock}
              onSelectSweet={props.onSelectSweet}
              types={product.types}
            />
          ))}
      </ul>
    </>
  );
};

export default Sweets;
