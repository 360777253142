import React from 'react';
import { NavLink } from 'react-router-dom';

const NavBar = () => {
  return (
    <div className='navbar'>
      <div className='navlink-container'>
        <NavLink to='/'>Home</NavLink>
      </div>
      <div className='navlink-container'>
        <NavLink to='/sweets'>Sweets</NavLink>
      </div>
      <div className='navlink-container'>
        <NavLink to='/order'>Order</NavLink>
      </div>
      <div className='navlink-container'>
        <NavLink to='/contact'>Contact</NavLink>
      </div>
    </div>
  );
};

export default NavBar;
