import React, { useState } from 'react';

import FullSizeSweet from './modals/FullSizeSweet';
const RecentlyAddedBagg = (props) => {
  const [visibleFullSize, setVisibleFullSize] = useState(false);

  const displayFullSize = () => {
    setVisibleFullSize(true);
  };

  const hideFullSize = () => {
    setVisibleFullSize(false);
  };

  return (
    <>
      <li className='large-sweet-container' onClick={displayFullSize}>
        <div className='product-image'>
          <img src={props.preview} alt={props.name} className='sweet-image' />
        </div>
        <div className='product-name'>
          <h3>{props.name}{!props.name.includes('Bag') && ' Bag'}</h3>
        </div>
      </li>
      {visibleFullSize && <FullSizeSweet onClose={hideFullSize} name={props.name} image={props.image} />}
    </>
  );
};

export default RecentlyAddedBagg;
