import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import AdminProductRequests from './AdminProductRequests';
import AdminEventRequests from './AdminEventRequests';
import CompletedOrderRequests from './CompletedOrderRequests';
import Button from '../UI/Button';
import Spinner from '../UI/Spinner';
import CompletedEventRequests from './CompletedEventRequests';
import { AuthContext } from '../context/auth-context';

const AdminRequests = () => {
  const auth = useContext(AuthContext);

  const [eventsLoading, setEventsLoading] = useState(false);
  const [productsLoading, setProductsLoading] = useState(false);
  const [eventRequests, setEventRequests] = useState([]);
  const [productRequests, setProductRequests] = useState([]);
  const [showProductRequests, setShowProductRequests] = useState(true);
  const [showEventRequests, setShowEventRequests] = useState(true);
  const [showCompletedProducts, setShowCompletedProducts] = useState(false);
  const [showCompletedEvents, setShowCompletedEvents] = useState(false);
  const [productResponse, setProductResponse] = useState();
  const [eventResponse, setEventResponse] = useState();

  // retrieves event enquiries
  useEffect(() => {
    setEventsLoading(true);
    const fetchEventRequests = async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/event-enquiry`, {
        headers: { Authorization: 'Bearer ' + auth.userData.token },
      });
      setEventRequests(data.sort((a, b) => new Date(b.submittedOn).getTime() - new Date(a.submittedOn).getTime()));
      setEventsLoading(false);
    };
    fetchEventRequests();
  }, [eventResponse, auth.userData.token]);

  // retrieves product enquiries
  useEffect(() => {
    setProductsLoading(true);
    const fetchProductRequests = async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/product-enquiry`, {
        headers: { Authorization: 'Bearer ' + auth.userData.token },
      });
      setProductRequests(data.sort((a, b) => new Date(b.submittedOn).getTime() - new Date(a.submittedOn).getTime()));
      setProductsLoading(false);
    };
    fetchProductRequests();
  }, [productResponse, auth.userData.token]);

  // updates event requests
  const updateEventsHandler = (res) => {
    setEventResponse(res);
  };

  // updates product requests
  const updateProductsHandler = (res) => {
    setProductResponse(res);
  };

  // displays completed product requests
  const showCompletedProductsHandler = () => {
    setShowProductRequests((prevState) => !prevState);
    setShowEventRequests((prevState) => !prevState);
    setShowCompletedProducts((prevState) => !prevState);
  };

  // displays completed event requests
  const showCompletedEventsHandler = () => {
    setShowProductRequests((prevState) => !prevState);
    setShowEventRequests((prevState) => !prevState);
    setShowCompletedEvents((prevState) => !prevState);
  };

  return (
    <>
      <h2>Requests</h2>
      {productsLoading ? (
        <Spinner />
      ) : (
        <>{showProductRequests && <AdminProductRequests productRequests={productRequests} onUpdate={updateProductsHandler} />}</>
      )}
      {eventsLoading ? (
        <Spinner />
      ) : (
        <>{showEventRequests && <AdminEventRequests eventRequests={eventRequests} onUpdate={updateEventsHandler} />}</>
      )}
      <div className='modal-actions'>
        {!showCompletedEvents && (
          <Button onClick={showCompletedProductsHandler}>{showCompletedProducts ? 'Back' : 'Completed Order Requests'}</Button>
        )}
        {!showCompletedProducts && (
          <Button onClick={showCompletedEventsHandler}>{showCompletedEvents ? 'Back' : 'Completed Event Requests'}</Button>
        )}
      </div>
      {showCompletedProducts && <CompletedOrderRequests />}
      {showCompletedEvents && <CompletedEventRequests />}
    </>
  );
};

export default AdminRequests;
