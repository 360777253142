import React from 'react';


import AllSweets from '../components/AllSweets';

const ViewSweets = (props) => {
  return (
    <>
      <h1>Sweets</h1>
      <h3>Here is where you'll find our full selection of sweets!</h3>
      <AllSweets />
    </>
  );
};

export default ViewSweets;
