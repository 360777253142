import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

import { AuthContext } from "../context/auth-context";

import { dayFormatter } from "../utils/dayFormatter";
import { dateFormatter } from "../utils/dateFormatter";
import { timeFormatter } from "../utils/timeFormatter";

const CompletedEventRequests = () => {
  const auth = useContext(AuthContext);

  const [completedEventRequests, setCompletedEventRequests] = useState([]);

  useEffect(() => {
    const retrieveCompletedEvents = async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/event-enquiry/complete`, {
        headers: { Authorization: "Bearer " + auth.userData.token },
      });
      setCompletedEventRequests(data.sort((a, b) => new Date(b.completed).getTime() - new Date(a.completed).getTime()));
    };
    retrieveCompletedEvents();
  }, [auth.userData.token]);

  return (
    <>
      {completedEventRequests.length === 0 && <p>You have no completed event requests!</p>}
      {completedEventRequests.length > 0 && (
        <>
          <h3>Completed Event Requests</h3>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Submitted</th>
                  <th>Name &amp; Email Address</th>
                  <th>Occasion</th>
                  <th>Date</th>
                  <th>Additional Info</th>
                  <th>Completed</th>
                </tr>
              </thead>
              <tbody>
                {completedEventRequests.map((request) => (
                  <tr key={request._id}>
                    <td>
                      {dateFormatter(request.submittedOn)}
                      <br />
                      {timeFormatter(request.submittedOn)}
                    </td>
                    <td>
                      <b>{request.name}</b>
                      <br />
                      {request.email}
                    </td>
                    <td>{request.occasion}</td>
                    <td>
                      {dayFormatter(request.submittedOn)}
                      <br />
                      {dateFormatter(request.date)}
                    </td>
                    <td>{request.specialRequirements ? request.specialRequirements : "N/A"}</td>
                    <td>
                      <p>
                        <b>Responded</b>
                        <br />
                        {dateFormatter(request.respondedDate)}
                        <br />
                        {timeFormatter(request.respondedDate)}
                      </p>
                      <hr />
                      <p>
                        <b>Completed</b>
                        <br />
                        {dateFormatter(request.completed)}
                        <br />
                        {timeFormatter(request.completed)}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default CompletedEventRequests;
