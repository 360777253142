import React, { useState, useRef, useContext } from 'react';
import axios from 'axios';

import Modal from '../../UI/Modal';
import Button from '../../UI/Button';
import Spinner from '../../UI/Spinner';
import { AuthContext } from '../../context/auth-context';

const ChangeEmail = (props) => {
  const auth = useContext(AuthContext);

  const emailInputRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const changeEmailHandler = async () => {
    let email = emailInputRef.current.value;
    setIsLoading(true);
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/edit/email/${auth.userData.userId}`,
        { email },
        { headers: { Authorization: 'Bearer ' + auth.userData.token } }
      );
      setIsLoading(false);
      setUpdated(true);
      auth.userData.email = email;
      localStorage.setItem('userData', JSON.stringify({ ...auth.userData, email: email }));
    } catch (err) {
      setIsLoading(false);
      setError(true);
      setErrorMessage(err.response.data.message);
      setTimeout(() => setError(false), 2000);
    }
  };

  return (
    <Modal onClose={props.onClose}>
      <div className='modal-title'>
        <h2>Change Email</h2>
      </div>
      <div className='modal-text'>
        <h2>{auth.userData.email}</h2>
      </div>
      {!updated && (
        <div className='modal-text'>
          <input id='email' type='email' ref={emailInputRef} placeholder='New Email' />
        </div>
      )}
      {updated && (
        <div className='modal-text'>
          <h3>Email updated successfully</h3>
        </div>
      )}
      {error && (
        <div className='error-modal-text'>
          <h3>{errorMessage}</h3>
        </div>
      )}
      <div className='modal-actions'>
        <Button className='remove-button' onClick={props.onClose}>
          Close
        </Button>
        {isLoading ? <Spinner /> : !updated && <Button onClick={changeEmailHandler}>Change Email</Button>}
      </div>
    </Modal>
  );
};

export default ChangeEmail;
