import React, { useState, useRef, useContext } from 'react';
import axios from 'axios';

import { AuthContext } from '../../../context/auth-context';

import Button from '../../../UI/Button';
import Spinner from '../../../UI/Spinner';
import '../../../DeleteProduct.css';
import { capitaliseWords } from '../../../utils/capitaliseWords';

const RemoveBaggItem = ({ id, name, image, preview, recentlyAdded, onRemove, onBaggDelete, onError, onErrorMessage }) => {
  const auth = useContext(AuthContext);

  const nameInputRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [edited, setEdited] = useState(false);
  const [isRecentlyAdded, setIsRecentlyAdded] = useState(recentlyAdded);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // toggles edit mode
  const editModeHandler = () => {
    setEditMode((prevState) => !prevState);
  };

  // edits bagg name
  const editBaggHandler = () => {
    const editBagg = async (newName) => {
      setIsLoading(true);
      try {
        await axios
          .put(
            `${process.env.REACT_APP_BACKEND_URL}/api/baggs/${id}`,
            { name: newName },
            { headers: { Authorization: 'Bearer ' + auth.userData.token } }
          )
          .then((res) => {
            setEditMode(false);
            onBaggDelete(res.data);
            setEdited(true);
            setIsLoading(false);
            setTimeout(() => setEdited(false), 2000);
          });
      } catch (err) {
        setError(true);
        setErrorMessage(err.response.data.message);
        setIsLoading(false);
        setTimeout(() => setError(false), 2000);
      }
    };

    if (nameInputRef.current.value === '') {
      setError(true);
      setErrorMessage('Name empty!');
      setTimeout(() => setError(false), 2000);
      return;
    }

    let newName = capitaliseWords(nameInputRef.current.value);
    editBagg(newName);
  };

  // deletes one bagg
  const deleteBaggHandler = () => {
    const deleteBagg = async () => {
      try {
        await axios
          .delete(`${process.env.REACT_APP_BACKEND_URL}/api/baggs/${id}`, {
            headers: { Authorization: 'Bearer ' + auth.userData.token },
            data: { image: image, preview: preview },
          })
          .then((res) => {
            onRemove(true);
            onBaggDelete(res.data);
            setTimeout(() => {
              onRemove(false);
            }, 2000);
          });
      } catch (err) {
        onError(true);
        onErrorMessage(err.response.data.message);
        setTimeout(() => onError(false), 2000);
      }
    };
    deleteBagg();
  };

  // updates recently added sweets on home page
  const recentlyAddedHandler = () => {
    const updateRecentlyAddedHandler = async () => {
      try {
        await axios
          .put(
            `${process.env.REACT_APP_BACKEND_URL}/api/baggs/recently-added/${id}`,
            { recentlyAdded: !isRecentlyAdded },
            { headers: { Authorization: 'Bearer ' + auth.userData.token } }
          )
          .then(() => {
            setIsRecentlyAdded((prevState) => !prevState);
          });
      } catch (err) {
        onError(true);
        onErrorMessage(err.response.data.message);
        setTimeout(() => onError(false), 2000);
      }
    };
    updateRecentlyAddedHandler();
  };

  return (
    <li className={'delete-product-container'}>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className='sweet-info-container'>
            <h4>New?</h4>
            <input type='checkbox' className='checkbox' checked={isRecentlyAdded} onChange={recentlyAddedHandler} />
          </div>
          <div className='sweet-image-container'>
            <img src={preview} alt={name} className='image' />
            <div className='sweet-image-centered-text'>
              <h3>{name} Bag</h3>
            </div>
          </div>
        </>
      )}
      {!isLoading && editMode && <input ref={nameInputRef} placeholder='New name?' />}
      {!isLoading && !editMode && (
        <span>
          <Button onClick={editModeHandler}>Edit</Button>
          <button className='remove-button' onClick={deleteBaggHandler}>
            Delete
          </button>
        </span>
      )}
      {!isLoading && editMode && (
        <span>
          <button className='remove-button' onClick={editModeHandler}>
            Back
          </button>
          <Button onClick={editBaggHandler}>Confirm</Button>
        </span>
      )}
      {edited && (
        <div className='modal-text' style={{ margin: 0 }}>
          <h3>Updated!</h3>
        </div>
      )}
      {error && (
        <div className='error-modal-text' style={{ margin: 0 }}>
          <h3>{errorMessage}</h3>
        </div>
      )}
    </li>
  );
};

export default RemoveBaggItem;
