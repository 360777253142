import React from 'react';

import { convertToKilos } from '../../../utils/convertToKilos';

const ConfirmOrderItem = (props) => {
  // removes item from basket
  const removeBaggHandler = () => {
    props.onRemoveBagg(props.id);
  };

  return (
    <div>
      <div className='enquiry-item'>
        <div>
          <p>
            <b>{props.quantity} x {convertToKilos(props.size)}</b> bag of <b>{props.bagg}</b> sweets
            {props.bagg !== 'Build Your Own' ? null : ' with:'}
          </p>
          {props.sweets.length > 0 && (
            <ul>
              {props.sweets.map((sweet) => (
                <li key={sweet.sweet}>{sweet.sweet}</li>
              ))}
            </ul>
          )}
        </div>
        <button className='delete-button' onClick={removeBaggHandler}>
          X
        </button>
      </div>
      <hr />
    </div>
  );
};

export default ConfirmOrderItem;
