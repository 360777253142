import React, { useState, useRef, useContext } from "react";
import axios from "axios";

import Button from "../UI/Button";
import Spinner from "../UI/Spinner";
import ImageUpload from "../UI/ImageUpload";
import { capitaliseWords } from "../utils/capitaliseWords";
import { AuthContext } from "../context/auth-context";
import { removeFromArray } from "../utils/removeFromArray";

const AddExtra = (props) => {
  const auth = useContext(AuthContext);

  const nameInputRef = useRef();
  const priceInputRef = useRef();
  const stockCountRef = useRef();
  const [extraType, setExtraType] = useState([]);
  const [added, setAdded] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // selects image
  const selectImageHandler = (selectedImage) => {
    setImage(selectedImage);
  };

  // updates gluten free
  const glutenFreeHandler = () => {
    if (!extraType.includes("glutenFree")) {
      setExtraType((extraType) => {
        return [...extraType, "glutenFree"];
      });
    }
    if (extraType.includes("glutenFree")) {
      let removedFilter = [
        ...new Set(removeFromArray(extraType, "glutenFree")),
      ];
      setExtraType(() => removedFilter);
    }
  };

  // updates gluten free
  const vegetarianHandler = () => {
    if (!extraType.includes("vegetarian")) {
      setExtraType((extraType) => {
        return [...extraType, "vegetarian"];
      });
    }
    if (extraType.includes("vegetarian")) {
      let removedFilter = [
        ...new Set(removeFromArray(extraType, "vegetarian")),
      ];
      setExtraType(() => removedFilter);
    }
  };

  // updates gluten free
  const veganHandler = () => {
    if (!extraType.includes("vegan")) {
      setExtraType((extraType) => {
        return [...extraType, "vegan"];
      });
    }
    if (extraType.includes("vegan")) {
      let removedFilter = [...new Set(removeFromArray(extraType, "vegan"))];
      setExtraType(() => removedFilter);
    }
  };

  // posts new sweet
  const postExtra = async (name, price, image, stockCount, types) => {
    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("price", price);
      formData.append("image", image);
      formData.append("stockCount", stockCount);
      formData.append("types", types);
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/api/extras`, formData, {
          headers: { Authorization: "Bearer " + auth.userData.token },
        })
        .then((res) => {
          props.onRefreshExtras(res.data);
          setIsLoading(false);
          setAdded(true);
          setTimeout(() => {
            nameInputRef.current.value = "";
            priceInputRef.current.value = "";
            stockCountRef.current.value = "";
            setExtraType([]);
            setAdded(false);
            setImage(null);
          }, 2000);
        });
    } catch (err) {
      setIsLoading(false);
      setError(true);
      setErrorMessage(err.response.data.message);
      setTimeout(() => setError(false), 2000);
    }
  };

  // validates and adds new sweet
  const addExtraHandler = () => {
    if (!nameInputRef.current.value) {
      setError(true);
      setErrorMessage("Please enter a name for your new other product!");
      setTimeout(() => setError(false), 2000);
      return;
    }
    if (!priceInputRef.current.value) {
      setError(true);
      setErrorMessage("Please select a price for your new other product!");
      setTimeout(() => setError(false), 2000);
      return;
    }
    if (!stockCountRef.current.value) {
      setError(true);
      setErrorMessage(
        "Please select a number of stock for your new other product!"
      );
      setTimeout(() => setError(false), 2000);
      return;
    }
    if (!image) {
      setError(true);
      setErrorMessage("Please upload an image for your new other product!");
      setTimeout(() => setError(false), 2000);
      return;
    }
    const name = capitaliseWords(nameInputRef.current.value);
    const price = priceInputRef.current.value;
    const stockCount = stockCountRef.current.value;
    if (props.extras.some((e) => e.name === name)) {
      setError(true);
      setErrorMessage("Other product already exists!");
      setTimeout(() => setError(false), 2000);
      return;
    }
    postExtra(name, price, image, stockCount, extraType);
  };

  // resets errors
  const resetHandler = () => {
    setError(false);
  };

  return (
    <>
      <h2>Add Other Product</h2>
      <div className="modal-text">
        <input
          type="text"
          ref={nameInputRef}
          size="50"
          onFocus={resetHandler}
          placeholder="Other Product Name"
        />
      </div>
      <div className="modal-text">
        <input
          type="text"
          ref={priceInputRef}
          size="10"
          maxLength="6"
          onFocus={resetHandler}
          placeholder="Price (£)"
          style={{ width: "auto", margin: "0px 20px" }}
        />
        <input
          type="text"
          ref={stockCountRef}
          size="10"
          maxLength="6"
          onFocus={resetHandler}
          placeholder="Stock count"
          style={{ width: "auto", margin: "0px 20px" }}
        />
      </div>
      <ImageUpload
        id="image"
        onSelectImage={selectImageHandler}
        onDelete={image}
      />
      <div className="modal-text">
        <div className="img-gluten-text" style={{ position: "static" }}>
          GF
        </div>
        <div>
          <input
            type="checkbox"
            className="checkbox"
            checked={extraType.includes("glutenFree")}
            onChange={glutenFreeHandler}
          />
        </div>
        <div
          className="img-veggie-text"
          style={{ position: "static", marginLeft: "10px" }}
        >
          V
        </div>
        <div>
          <input
            type="checkbox"
            className="checkbox"
            checked={extraType.includes("vegetarian")}
            onChange={vegetarianHandler}
          />
        </div>
        <div
          className="img-vegan-text"
          style={{ position: "static", marginLeft: "10px" }}
        >
          Vg
        </div>
        <div>
          <input
            type="checkbox"
            className="checkbox"
            checked={extraType.includes("vegan")}
            onChange={veganHandler}
          />
        </div>
      </div>
      <div className="modal-actions">
        {isLoading ? (
          <Spinner />
        ) : (
          <Button onClick={addExtraHandler}>Add Other Product</Button>
        )}
      </div>
      {added && (
        <div className="modal-text">
          <h3>
            '{capitaliseWords(nameInputRef.current.value)}' added successfully!
          </h3>
        </div>
      )}
      {error && (
        <div className="error-modal-text">
          <h3>{errorMessage}</h3>
        </div>
      )}
    </>
  );
};

export default AddExtra;
