import React, { useState, useRef, useContext } from 'react';
import axios from 'axios';

import Modal from '../../UI/Modal';
import Button from '../../UI/Button';
import Spinner from '../../UI/Spinner';
import { AuthContext } from '../../context/auth-context';

const ChangeName = (props) => {
  const auth = useContext(AuthContext);

  const oldPasswordRef = useRef();
  const newPasswordRef = useRef();
  const ConfirmPasswordRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const changeNameHandler = async () => {
    setIsLoading(true);
    let oldPassword = oldPasswordRef.current.value;
    let newPassword = newPasswordRef.current.value;
    let confirmPassword = ConfirmPasswordRef.current.value;
    if (newPassword !== confirmPassword) {
      setIsLoading(false);
      setError(true);
      setErrorMessage('Passwords do not match!');
      setTimeout(() => setError(false), 2000);
      return;
    }
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/edit/password/${auth.userData.userId}`,
        { oldPassword, newPassword },
        { headers: { Authorization: 'Bearer ' + auth.userData.token } }
      );
      setIsLoading(false);
      setUpdated(true);
    } catch (err) {
      setIsLoading(false);
      setError(true);
      setErrorMessage(err.response.data.message);
      setTimeout(() => setError(false), 3000);
    }
  };

  return (
    <Modal onClose={props.onClose}>
      <div className='modal-title'>
        <h2>Change Password</h2>
      </div>
      {!updated && (
        <div className='modal-text'>
          <input id='oldPassword' type='password' ref={oldPasswordRef} placeholder='Old Password' />
          <input id='newPassword' type='password' ref={newPasswordRef} placeholder='New Password' />
          <input id='confirmPassword' type='password' ref={ConfirmPasswordRef} placeholder='Confirm New Password' />
        </div>
      )}
      {updated && (
        <div className='modal-text'>
          <h3>Password updated successfully</h3>
        </div>
      )}
      {error && (
        <div className='error-modal-text'>
          <h3>{errorMessage}</h3>
        </div>
      )}
      <div className='modal-actions'>
        <Button className='remove-button' onClick={props.onClose}>
          Close
        </Button>
        {isLoading ? <Spinner /> : !updated && <Button onClick={changeNameHandler}>Change Password</Button>}
      </div>
    </Modal>
  );
};

export default ChangeName;
