import React, { useState, useContext } from 'react';

import ChangeName from './modals/ChangeName';
import ChangeEmail from './modals/ChangeEmail';
import ChangePassword from './modals/ChangePassword';
import Card from '../UI/Card';
import Button from '../UI/Button';
import { AuthContext } from '../context/auth-context';

const AdminAdmin = () => {
  const auth = useContext(AuthContext);

  const [showChangeName, setShowChangeName] = useState(false);
  const [showChangeEmail, setShowChangeEmail] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);

  const changeNameHandler = () => {
    setShowChangeName(true);
  };
  const changeEmailHandler = () => {
    setShowChangeEmail(true);
  };
  const changePasswordHandler = () => {
    setShowChangePassword(true);
  };

  return (
    <>
      <h2>Hello {auth.userData.name}!</h2>
      <div className='admin-widgets'>
        <Card className='admin-card'>
          <h2>Change details</h2>
          <Button onClick={changeNameHandler}>Name</Button>
          <Button onClick={changeEmailHandler}>Email</Button>
          <Button onClick={changePasswordHandler}>Password</Button>
        </Card>
      </div>
      {showChangeName && <ChangeName onClose={() => setShowChangeName(false)} />}
      {showChangeEmail && <ChangeEmail onClose={() => setShowChangeEmail(false)} />}
      {showChangePassword && <ChangePassword onClose={() => setShowChangePassword(false)} />}
    </>
  );
};

export default AdminAdmin;
