import React, { useState } from 'react';

import AdminAdmin from './AdminAdmin';
import AdminRequests from './AdminRequests';
import AdminProducts from './AdminProducts';
import Button from '../UI/Button';

const Admin = () => {
  const [screen, setScreen] = useState('requests');

  // switches between admin screens
  const showRequestsHandler = () => {
    setScreen('requests');
  };
  const showProductsHandler = () => {
    setScreen('products');
  };
  const showAdminHandler = () => {
    setScreen('admin');
  };

  return (
    <>
      <h1>Admin</h1>
      <div className='admin-container'>
        <div className='admin-widgets'>
          <Button onClick={showRequestsHandler}>Requests</Button>
          <Button onClick={showProductsHandler}>Products</Button>
          <Button onClick={showAdminHandler}>Admin</Button>
        </div>
        {screen === 'requests' && <AdminRequests />}
        {screen === 'products' && <AdminProducts />}
        {screen === 'admin' && <AdminAdmin />}
      </div>
    </>
  );
};

export default Admin;
