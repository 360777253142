import React, { useState, useRef } from 'react';

import EventEnquiry from './modals/EventEnquiry';
import Button from '../UI/Button';
import { capitalise } from '../utils/capitaliseWord';

const EventForm = () => {
  const nameInputRef = useRef();
  const emailInputRef = useRef();
  const occasionInputRef = useRef();
  const dateInputRef = useRef();
  const specialRequirementsInputRef = useRef();
  const [showEventEnquiry, setShowEventEnquiry] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // validates inputs and when successful shows confirm event enquiry
  const showEventEnquiryHandler = (event) => {
    const name = capitalise(nameInputRef.current.value);
    const email = emailInputRef.current.value;
    const occasion = occasionInputRef.current.value;
    const date = dateInputRef.current.value;
    event.preventDefault();
    if (name === '') {
      setError(true);
      setErrorMessage('Please enter your name.');
      return;
    }
    if (email === '') {
      setError(true);
      setErrorMessage('Please enter your email address.');
      return;
    }
    if (!email.includes('@') || !email.includes('.')) {
      setError(true);
      setErrorMessage('Please enter a valid email address.');
      return;
    }
    if (occasion === '') {
      setError(true);
      setErrorMessage('Please enter your event/occasion type.');
      return;
    }
    if (date === '') {
      setError(true);
      setErrorMessage('Please enter the date you would like to book.');
      return;
    }
    if (new Date(date) < new Date()) {
      setError(true);
      setErrorMessage('Invalid date, please select a date in the future.');
      return;
    }
    setShowEventEnquiry(true);
  };

  // resets input values
  const resetValues = () => {
    nameInputRef.current.value = '';
    emailInputRef.current.value = '';
    occasionInputRef.current.value = '';
    dateInputRef.current.value = '';
    specialRequirementsInputRef.current.value = '';
  };

  // closes confirm modal
  const hideEventEnquiryHandler = () => {
    setShowEventEnquiry(false);
  };

  return (
    <>
      <h2>Event Enquiries</h2>
      <form className='enquiry-form' onSubmit={showEventEnquiryHandler}>
        <input type='text' id='name' placeholder='Name*' required='required' ref={nameInputRef} onFocus={() => setError(false)} />
        <input
          type='email'
          id='email'
          placeholder='Email Address*'
          required='required'
          ref={emailInputRef}
          onFocus={() => setError(false)}
        />
        <input
          type='text'
          id='occasion'
          placeholder='Event/Occasion*'
          required='required'
          ref={occasionInputRef}
          onFocus={() => setError(false)}
        />
        <div>
        <input type='date' id='date' required='required' ref={dateInputRef} onFocus={() => setError(false)} />
        <b style={{ color: '#F71B0F' }}>*</b>
        </div>
        <textarea
          rows='5'
          maxLength='255'
          placeholder='Additional information eg. number of people/types of sweets'
          ref={specialRequirementsInputRef}
          onFocus={() => setError(false)}
        />
        <p style={{ color: '#F71B0F', fontWeight: 'bold'}}>* Required Fields</p>
        <Button type='submit'>Submit</Button>
      </form>
      {error && (
        <div className='error-modal-text'>
          <h3>{errorMessage}</h3>
        </div>
      )}
      {showEventEnquiry && (
        <EventEnquiry
          onClose={hideEventEnquiryHandler}
          onResetValues={resetValues}
          name={nameInputRef.current.value}
          email={emailInputRef.current.value}
          occasion={occasionInputRef.current.value}
          date={dateInputRef.current.value}
          specialRequirements={specialRequirementsInputRef.current.value}
        />
      )}
    </>
  );
};

export default EventForm;
