import React, { useState, useContext } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import Modal from '../../UI/Modal';
import Button from '../../UI/Button';
import Spinner from '../../UI/Spinner';
import { AuthContext } from '../../context/auth-context';
import { convertToKilos } from '../../utils/convertToKilos';

const ConfirmProductComplete = (props) => {
  const auth = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [cancelled, setCancelled] = useState(false);

  // filters unique extras from order
  let uniqueExtras = (array) => {
    return [...new Map(array.map((item) => [item['name'], item])).values()];
  };

  // counts how many of each extra there is
  const extraCounter = (array, item) => {
    let counter = 0;
    for (let i = 0; i < array.length; i++) {
      if (array[i].name === item) counter++;
    }
    return counter;
  };

  // completes request
  const cancelProductReq = async (order) => {
    setIsLoading(true);
    try {
      await axios
        .delete(`${process.env.REACT_APP_BACKEND_URL}/api/product-enquiry/cancel/${order._id}`, {
          headers: { Authorization: 'Bearer ' + auth.userData.token },
        })
        .then((res) => {
          setTimeout(() => {
            props.onUpdate(res.data);
            props.onHide();
          }, 2000);
          setIsLoading(false);
          setCancelled(true);
        });
    } catch (err) {
      setError(true);
      setErrorMessage(err.response.data.message);
      setIsLoading(false);
      setTimeout(() => setError(false), 2000);
    }
  };

  return (
    <Modal onClose={props.onHide}>
      <div className='modal-cancel-title'>
        <h2>Are you sure you want to cancel?</h2>
      </div>
      <div className='modal-column'>
        <div>
          <h3>{props.request.name}</h3>
          <p>{props.request.email}</p>
          <hr />
        </div>
        <div>
          {props.request.order.map(
            (b) =>
              b.baggs.length > 0 &&
              b.baggs
                .map((o) => (
                  <React.Fragment key={o.id}>
                    <h3>
                      {o.quantity || '1'} x {convertToKilos(o.size)} {o.bagg}
                    </h3>
                    {o.sweets.length > 0 && (
                      <p>{o.sweets.map((s) => <React.Fragment key={s.sweet}>{s.sweet}</React.Fragment>).reduce((prev, curr) => [prev, ', ', curr])}</p>
                    )}
                  </React.Fragment>
                ))
                .reduce((prev, curr) => [prev, <hr key={uuidv4()} />, curr])
          )}
          {props.request.order.map((b) => b.baggs.length > 0 && b.extras.length > 0 && <hr key={uuidv4()} />)}
          {props.request.order.map(
            (b) =>
              b.extras.length > 0 &&
              uniqueExtras(b.extras)
                .map((e) => <h3 key={e.id}>{`${extraCounter(b.extras, e.name)} x ${e.name}`}</h3>)
                .reduce((prev, curr) => [prev, <hr key={uuidv4()} />, curr])
          )}
        </div>
      </div>
      {cancelled && (
        <div className='modal-text'>
          <h3>Request Cancelled!</h3>
        </div>
      )}
      {error && (
        <div className='error-modal-text'>
          <h3>{errorMessage}</h3>
        </div>
      )}
      <div className='modal-actions'>
        <Button className='remove-button' onClick={props.onHide}>
          Close
        </Button>
        {isLoading ? (
          <Spinner />
        ) : (
          !cancelled && (
            <Button className='remove-button' onClick={() => cancelProductReq(props.request)}>
              Cancel Request
            </Button>
          )
        )}
      </div>
    </Modal>
  );
};

export default ConfirmProductComplete;
